<template>
  <v-container fluid style="width: 100%; height: 100%">
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="yellow darken-2"
    ></v-progress-linear>

      <v-card v-if="localData" class="mt-5" outlined>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="saveCompanyBtn"
            elevation="2"
            @click="submit()"
            dark
            color="green"
            class="text-none rounded-lg"
          >{{ $t("buttons.save") }}
          </v-btn>
          <v-btn
            elevation="2"
            @click="close()"
            class="text-none rounded-lg"
            v-if="displayCompanyItem"
          >{{ $t("buttons.close") }}
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <EditCompanyGeneral :data="localData" :is_learning_sso_enable="is_learning_sso_enable" ref="editGeneral" />

          <EditCompanyAzureAD
            :data="localData"
            ref="editAzureAD"
          />

          <EditCompanySSO :data="localData" ref="editSSO" @update-learning-sso-status="updateLearningSsoStatus" />

          <EditCompanyDomains
            :data="localData"
            ref="editDomains"
            v-if="isSystemAdmin"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            elevation="2"
            @click="submit()"
            dark
            color="green"
            class="text-none rounded-lg"
          >{{ $t("buttons.save") }}
          </v-btn>
          <v-btn
            elevation="2"
            @click="close()"
            class="text-none rounded-lg"
            v-if="displayCompanyItem"
          >{{ $t("buttons.close") }}
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <EditCompanySubscription :data="localData" v-if="isSubscriptionManager" />
          <EditCompanyCustomization :data="localData" v-if="isSystemAdmin" />
        </v-card-text>
      </v-card>

  </v-container>
</template>

<script>
import EditCompanyGeneral from "../../components/company-edition/EditCompanyGeneral.vue"
import EditCompanyAzureAD from "../../components/company-edition/EditCompanyAzureAD.vue"
import EditCompanyDomains from "../../components/company-edition/EditCompanyDomains.vue"
import EditCompanySSO from "../../components/company-edition/EditCompanySSO.vue"
import EditCompanySubscription from "../../components/company-edition/EditCompanySubscription.vue"
import EditCompanyCustomization from "../../components/company-edition/EditCompanyCustomization.vue"
import CompanyLogic from "@/logics/CompanyLogic"
import companiesMixin from "@/mixins/companies.mixins.js"
import { showSnackbar } from "@/services/GlobalActions"
import { i18n } from "@/plugins/i18n"
import AuthStore from "@/stores/AuthStore"
import { cloneDeep } from "lodash"

export default {
  mixins: [companiesMixin],
  name: "company-edition",
  components: {
    EditCompanyGeneral,
    EditCompanyDomains,
    EditCompanySSO,
    EditCompanyAzureAD,
    EditCompanySubscription,
    EditCompanyCustomization,
  },
  props: {
    data: {
      default: null,
    },
    displayCompanyItem: {
      default: true,
    },
  },
  data: function() {
    return {
      loading: true,
      localData: null,
      isSystemAdmin: false,
      isSubscriptionManager: false,
      companyUuid: null,
      is_learning_sso_enable: false,
    }
  },
  async mounted() {
    this.isSystemAdmin = await AuthStore.hasSystemRole("system-admin")

    if (this.$route.params.companyUuid) {
      this.companyUuid = this.$route.params.companyUuid
      this.isSubscriptionManager = await AuthStore.hasCompanyRole("company-subscription-manager", this.companyUuid)
       const company = await CompanyLogic.getOne(this.companyUuid, {
        with_active_directory_credentials: 1,
      })
      this.refreshLocalData(company)
    } else {
      this.localData = JSON.parse(JSON.stringify(this.data))
    }
    this.loading = false
  },
  methods: {
    refreshLocalData(company){
      this.company = company
      this.localData = JSON.parse(JSON.stringify(company))
    },
    async submit() {
      let isValid = true

      for (let componentRef in this.$refs) {
        // Validate the forms in each component
        isValid = isValid && (await this.$refs[componentRef].validate())
      }

      if (!isValid) {
        console.error("Validation failed")
        return
      }

      let companyData = cloneDeep(this.localData)
      for (let componentRef in this.$refs) {
        const componentCompanyData = await this.$refs[componentRef].getCompanyData()
        // GET the values from all the components
        companyData = Object.assign(
          companyData,
          componentCompanyData,
        )
      }

      if (this.$route.params.companyUuid) {
        this.localData = null;
        const company = await this.saveCompany(companyData);
        if (company) {
          showSnackbar(i18n.t("view.companies.edition_save"), "#1AC8AA")
          this.refreshLocalData(company)
          this.$emit("update-company", company)
        }
      }
      else {
        this.$emit("save", companyData)
      }
    },
    close() {
      if (this.$route.params.companyUuid) {
        this.$router.push(`/company-management/${this.company.uuid}/programs`)
      } else {
        this.$emit("close")
      }
    },
    updateLearningSsoStatus(status) {
      this.is_learning_sso_enable = status
    },
  },
}
</script>

<style scoped></style>
