<template>
  <div class="automation-icons" v-if="entity">
    <v-tooltip
      bottom
      v-if="typeof entity.has_automatic_invites !== 'undefined'"
    >
      <template v-slot:activator="{ on }">
        <v-icon
          :color="entity.has_automatic_invites ? 'green' : 'grey'"
          small
          v-on="on"
          class="mx-1"
        >
          mdi-email-outline
        </v-icon>
      </template>
      <span>{{ $t("view.companies.automatic_invitation") }}</span>
    </v-tooltip>

    <v-tooltip bottom v-if="typeof entity.has_automatic_launch !== 'undefined'">
      <template v-slot:activator="{ on }">
        <v-icon
          :color="entity.has_automatic_launch ? 'blue' : 'grey'"
          small
          v-on="on"
          class="mx-1"
        >
          mdi-rocket
        </v-icon>
      </template>
      <span>{{ $t("view.companies.automatic_launch") }}</span>
    </v-tooltip>

    <v-tooltip
      bottom
      v-if="typeof entity.has_automatic_reminders !== 'undefined'"
    >
      <template v-slot:activator="{ on }">
        <v-icon
          :color="entity.has_automatic_reminders ? 'orange' : 'grey'"
          small
          v-on="on"
          class="mx-1"
        >
          mdi-bell-outline
        </v-icon>
      </template>
      <span>{{ $t("view.companies.automatic_reminders") }}</span>
    </v-tooltip>

    <v-tooltip bottom v-if="typeof entity.has_automatic_stop !== 'undefined'">
      <template v-slot:activator="{ on }">
        <v-icon
          :color="entity.has_automatic_stop ? 'red' : 'grey'"
          small
          v-on="on"
          class="mx-1"
        >
          mdi-stop-circle-outline
        </v-icon>
      </template>
      <span>{{ $t("label.automatic_stop") }}</span>
    </v-tooltip>

    <v-tooltip bottom v-if="typeof entity.has_automatic_report !== 'undefined'">
      <template v-slot:activator="{ on }">
        <v-icon
          :color="entity.has_automatic_report ? 'purple' : 'grey'"
          small
          v-on="on"
          class="mx-1"
        >
          mdi-file-document
        </v-icon>
      </template>
      <span>{{ $t("view.companies.automatic_report") }}</span>
    </v-tooltip>

    <span v-if="entity.automatic_duration_days && entity.has_automatic_stop">

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mx-1"
        >

          <b>{{ entity.automatic_duration_days }}</b
        >j</span
        >
      </template>
      <span>{{ $t("label.duration") }}</span>
    </v-tooltip>
      </span>


    <v-tooltip bottom v-if="typeof entity.has_automatic_employee_group_refresh !== 'undefined'">
      <template v-slot:activator="{ on }">
        <v-icon
          :color="entity.has_automatic_employee_group_refresh ? 'pink' : 'grey'"
          small
          v-on="on"
          class="mx-1"
        >
          mdi-refresh
        </v-icon>
      </template>
      <span>{{ $t("view.companies.automatic_employee_group_refresh") }}</span>
    </v-tooltip>



  </div>
</template>

<script>
export default {
  name: "op-automation-component",
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
.automation-icons {
  display: flex;
  align-items: center;
}
</style>
