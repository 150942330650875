import MonitoringLogic from "@/logics/MonitoringLogic"
import utilsMixin from "@/mixins/utils.mixin"

export default {
  mixins: [utilsMixin],
  data: () => ({}),
  methods: {
    async getAllMonitoredServers(isMonitoringActive=false) {
      return this._withLoading(() => MonitoringLogic.getAllEntities(isMonitoringActive))
    },

    suppressMonitoring(data) {
      return this._withLoading(async () =>
        MonitoringLogic.deleteMonitoring(data.id)
      )
    },

    monitorServer(data) {
      return this._withLoading(async () => {
        if (null === data.send_by_date) {
          data.send_by_time = null
        }
        return await MonitoringLogic.pushMonitoring(data)
      })
    },

    getOneMonitoring(data) {
      return this._withLoading(() => MonitoringLogic.getOneMonitoring(data.id))
    },

    notificationSlack() {
      return this._withLoading(() => MonitoringLogic.pushNotificationSlack())
    },

    getServerStatus(data) {
      return this._withLoading(() => MonitoringLogic.getOneServerStatus(data))
    },
  },
}
