import PhishingRepository from "../repositories/PhishingRepository"
import LearningRepository from "@/repositories/LearningRepository"
import CompanyRepository from "@/repositories/CompanyRepository"

export default class PhishingLogic {
  //------------------- Generic Resources  -------------------
  static async getPhishingResources(type) {
    const response = await PhishingRepository.getResources(type)
    return response.responseObject().data
  }

  static async deletePhishingResource(type, data) {
    const response = await PhishingRepository.deleteOneResource(type, data.id)

    return response.responseObject().data
  }

  static async savePhishingResource(type, object) {
    let response
    if (object.id) {
      response = await PhishingRepository.patchOneResource(type, object)
    } else {
      response = await PhishingRepository.createOneResource(type, object)
    }

    let list = response.responseObject()
    return list.data
  }

  static async duplicateResource(type, data) {
    const response = await PhishingRepository.duplicateOneResource(
      type,
      data.id
    )
    return response.responseObject().data
  }

  //------------------- Email Templates -------------------
  static async getEmailTemplateHTMLPreview($templateId) {
    const response = await PhishingRepository.getEmailTemplateHTMLPreview(
      $templateId
    )
    let list = response.responseObject()
    return list.data
  }

  //------------------- Exercises -------------------
  static async getExerciseTemplateTagsList(exerciseId) {
    const response = await PhishingRepository.getExerciseTemplateTagsList(
      exerciseId
    )
    let list = response.responseObject()
    return list.data
  }

  static async getExerciseCompiledTemplates(exerciseId) {
    const response = await PhishingRepository.getExerciseCompiledTemplates(
      exerciseId
    )
    let list = response.responseObject()
    return list.data
  }

  static async getExerciseCompiledWithTemplateTags(exerciseId, tags) {
    const response =
      await PhishingRepository.getExerciseCompiledWithTemplateTags(
        exerciseId,
        tags
      )
    let list = response.responseObject()
    return list.data
  }

  //------------------- Generic CRUD calls on -------------------

  static async pushEntity(data, path) {
    let response
    if (data.id) {
      response = await PhishingRepository.patchOneEntity(data, path)
    } else {
      response = await PhishingRepository.createOneEntity(data, path)
    }

    let list = response.responseObject()
    return list.data
  }

  static async getAllEntities(path) {
    const response = await PhishingRepository.getAllEntities(path)
    let list = response.responseObject()
    return list.data
  }

  static async getOneEntity(path, entityId) {
    const response = await PhishingRepository.getOneEntity(path, entityId)
    return response.responseObject()
  }

  static async deleteEntity(entityId, path) {
    const response = await PhishingRepository.deleteOneEntity(entityId, path)
    return response.responseObject()
  }

  static async archiveExercice(data) {
    if (!data.id) {
      return
    }
    const response = await PhishingRepository.archiveExercice(data.id)
    return response.responseObject()
  }

  //------------------- Simulations -------------------

  // @todo rename function from campaigns to simulation-results

  static async getAllSimulations(companyUuid) {
    const response = await PhishingRepository.getAllSimulations(companyUuid)
    let list = response.responseObject().data
    return list.simulations
  }

  static async getOneSimulation(companyUuid, simulationId, withStats = false) {
    const response = await PhishingRepository.getOneSimulation(
      companyUuid,
      simulationId,
      withStats
    )
    return response.responseObject().data
  }

  static async cloneOneSimulation(companyUuid, simulationId) {
    const response = await PhishingRepository.cloneOneSimulation(
      companyUuid,
      simulationId
    )
    return response.responseObject().data
  }

  /**
   *
   * @param companyUuid
   * @param params
   * @returns {Promise}
   */
  static async getEmployees(companyUuid, params = {}) {
    const res = await PhishingRepository.getEmployees(companyUuid, params)
    return res?.response?.data
  }

  static async getOneSimulationEmployees(
    companyUuid,
    simulationId,
    page_size = 25,
    page = 1
  ) {
    const response = await PhishingRepository.getOneSimulationEmployees(
      companyUuid,
      simulationId,
      page_size,
      page
    )
    return response.responseObject()
  }

  static async pushSimulation(companyUuid, simulation) {
    let response
    if (simulation.id && !simulation.is_active && !simulation.is_stopped) {
      response = await PhishingRepository.updateOneSimulation(
        companyUuid,
        simulation
      )
    } else if (simulation.id) {
      response = await PhishingRepository.patchOneSimulation(
        companyUuid,
        simulation
      )
    } else {
      response = await PhishingRepository.createOneSimulation(
        companyUuid,
        simulation
      )
    }

    let list = response.responseObject()
    return list.data
  }

  static async deleteSimulation(companyUuid, simulationId) {
    const response = await PhishingRepository.deleteOneSimulation(
      companyUuid,
      simulationId
    )
    return response.responseObject()
  }

  static async launchSimulation(companyUuid, simulationId) {
    const response = await PhishingRepository.startSimulation(
      companyUuid,
      simulationId
    )
    return response.responseObject()
  }

  static async stopSimulation(companyUuid, simulationId) {
    const response = await PhishingRepository.stopSimulation(
      companyUuid,
      simulationId
    )
    return response.responseObject()
  }

  static async getSimulationResults(companyUuid, simulationId) {
    const response = await PhishingRepository.getSimulationResults(
      companyUuid,
      simulationId
    )
    return response.responseObject().data
  }

  static async getOneSimulationIpAddresses(
    companyUuid,
    simulationId,
    page = 1,
    perPage = 15,
    sortBy = [],
    sortDesc = [],
    search = ""
  ) {
    const response = await PhishingRepository.getOneSimulationIpAddresses(
      companyUuid,
      simulationId,
      { page, per_page: perPage, sortBy, sortDesc, search }
    )
    return response.responseObject()
  }

  static async blacklistOneSimulationIp(companyUuid, simulationId, body) {
    const response = await PhishingRepository.blacklistOneSimulationIp(
      companyUuid,
      simulationId,
      body
    )
    return response.responseObject()
  }

  static async unblacklistOneSimulationIp(companyUuid, simulationId, ip) {
    const response = await PhishingRepository.unblacklistOneSimulationIp(
      companyUuid,
      simulationId,
      ip
    )
    return response.responseObject()
  }

  static async createSimulationTest(companyUuid, simulationId) {
    const response = await PhishingRepository.createSimulationTest(
      companyUuid,
      simulationId
    )
    let list = response.responseObject()
    return list.data
  }

  static async recomputeSimulationResults(companyUuid, simulationId) {
    const response = await PhishingRepository.recomputeSimulationResults(
      companyUuid,
      simulationId
    )
    let list = response.responseObject()
    return list.data
  }

  static async reportEmail(companyUuid, simulationId, data) {
    const response = await PhishingRepository.reportEmail(
      companyUuid,
      simulationId,
      data
    )
    let list = response.responseObject()
    return list.data
  }

  static async getOneSimulationTimeline(companyUuid, simulationId) {
    const response = await PhishingRepository.getOneSimulationTimeline(
      companyUuid,
      simulationId
    )
    let list = response.responseObject()
    return list.data
  }

  //------------------- Simulation compiled templates  -------------------
  static async getSimulationCompiledTemplates(companyUuid, simulationId) {
    const response = await PhishingRepository.getSimulationCompiledTemplates(
      companyUuid,
      simulationId
    )
    let list = response.responseObject()
    return list.data
  }

  //-------------------- landing pages, etc. ---------------
  static async getAllLandingPages() {
    const response = await PhishingRepository.getAllLandingPages()
    let list = response.responseObject()
    return list.data
  }

  static async getAllServers() {
    const response = await PhishingRepository.getAllServers()
    let list = response.responseObject()
    return list.data
  }

  static async getAllSendingProfiles() {
    const response = await PhishingRepository.getAllSendingProfiles()
    let list = response.responseObject()
    return list.data
  }

  static async getAllEmailTemplates() {
    const response = await PhishingRepository.getAllEmailTemplates()
    let list = response.responseObject()
    return list.data
  }

  static async testSMTPSendingProfile(data) {
    const response = await PhishingRepository.testSMTPSendingProfile(data)
    let list = response.responseObject()
    return list.data
  }

  //-------------------- export data -------------------
  static async export(companyUuid, simulationId, type) {
    try {
      const res = await PhishingRepository.export(
        companyUuid,
        simulationId,
        type
      )
      return res
    } catch (e) {
      return e.message
    }
  }
  //-------------------- phishing assets -------------------

  static async getDirectories() {
    const response = await PhishingRepository.getDirectories()
    return response.responseObject().data
  }

  static async createDirectory(name) {
    const response = await PhishingRepository.createDirectory(name)
    return response.responseObject().data
  }

  static async getFiles(directory) {
    const response = await PhishingRepository.getFiles(directory)
    return response.responseObject().data
  }

  static async deleteFile(formData) {
    const response = await PhishingRepository.deleteFile(formData)
    return response.responseObject().data
  }

  static async uploadFile(formData) {
    const response = await PhishingRepository.uploadFile(formData)
    return response.responseObject().data
  }
}
