<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" :class="[customClass]">{{ value }}</span>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "op-tooltip",
  props: {
    value: String,
    tooltip: String,
    customClass: {
      type: [String, Array, Object], // Allow multiple class formats
      default: "",
    },
  },
};
</script>
