import Request from "../services/Request"
import { API_VERSION } from "@/repositories/ApiVersion"

export default class AdminRepository {
  static getDashboard() {
    return Request.make("GET", `/admin/dashboard`)
  }

  static refreshDashboard() {
    return Request.make("GET", `/admin/dashboard/refresh`)
  }


  static getAllCompaniesNoPagination() {
    return Request.make("GET", `/admin/companies/all`)
  }

  static async getAllCompanies({ page, per_page, sortBy = [], sortDesc = [], search = '' }) {
    let params = {
      page,
      per_page,
      search,
    }

    if (sortBy.length > 0) {
      params.sort_by = sortBy[0]
      params.sort_desc = sortDesc[0]
    }

    return Request.make("GET", `/admin/companies`, { params })
  }


  static getAllCompaniesWithCreditConsumption() {
    return Request.make("GET", `/admin/companies/with-credit-consumption?only_msp=1`)
  }

  static createCompany(body = {}) {
    return Request.make("POST", `/admin/companies`, body)
  }

  static deleteCompany(companyUuid) {
    return Request.make("DELETE", `/admin/companies/${companyUuid}`, {})
  }

  static importCoursesAndHatCourses() {
    return Request.make("GET", `admin/import-oppens-learning-courses`, {})
  }

  // ------------------------ Command batch logs ------------------------//
  static getBatchCommandsLogs() {
    return Request.make("GET", `/admin/batch-commands-logs`)
  }

  //------------------------ Notifications templates (emails) ------------------------//
  static getAllNotificationsTemplates() {
    return Request.make("GET", `/admin/emails`, {})
  }

  static getNotificationTemplate(templateUuid) {
    return Request.make("GET", `/admin/emails/${templateUuid}`, {})
  }

  static createNotificationsTemplate(body) {
    return Request.make("POST", `/admin/emails`, body)
  }

  static updateNotificationsTemplate(templateUuid, body) {
    return Request.make("PATCH", `/admin/emails/${templateUuid}`, body)
  }

  static deleteNotificationsTemplate(templateUuid) {
    return Request.make("DELETE", `/admin/emails/${templateUuid}`, {})
  }

  static getAllStimuli() {
    return Request.make("GET", `/admin/stimuli`)
  }

  static getAllThematics() {
    return Request.make("GET", `/admin/thematics`)
  }

  //------------------------ Users ------------------------//
  static getUserPerimeters(userUuid, params = {}) {
    return Request.make("GET", `/${API_VERSION}/users/${userUuid}/perimeters`, {
      params,
    })
  }

  //------------------------ Headers & Footers Templates ------------------------//
  static getAllEmailFooters() {
    return Request.make("GET", `/admin/emails/footers`, {})
  }

  static getAllEmailHeaders() {
    return Request.make("GET", `/admin/emails/headers`, {})
  }

  static getEmailHeaderTemplate(templateUuid) {
    return Request.make("GET", `/admin/emails/headers/${templateUuid}`, {})
  }

  static createEmailHeaderTemplate(body) {
    return Request.make("POST", `/admin/emails/headers`, body)
  }

  static updateEmailHeaderTemplate(templateUuid, body) {
    return Request.make("PATCH", `/admin/emails/headers/${templateUuid}`, body)
  }

  static deleteEmailHeaderTemplate(templateUuid) {
    return Request.make("DELETE", `/admin/emails/headers/${templateUuid}`, {})
  }

  static duplicateEmailHeaderTemplate(templateUuid) {
    return Request.make("POST", `/admin/emails/headers/${templateUuid}/duplicate`, {})
  }

  static getEmailFooterTemplate(templateUuid) {
    return Request.make("GET", `/admin/emails/footers/${templateUuid}`, {})
  }

  static createEmailFooterTemplate(body) {
    return Request.make("POST", `/admin/emails/footers`, body)
  }

  static updateEmailFooterTemplate(templateUuid, body) {
    return Request.make("PATCH", `/admin/emails/footers/${templateUuid}`, body)
  }

  static deleteEmailFooterTemplate(templateUuid) {
    return Request.make("DELETE", `/admin/emails/footers/${templateUuid}`, {})
  }

  static duplicateEmailFooterTemplate(templateUuid) {
    return Request.make("POST", `/admin/emails/footers/${templateUuid}/duplicate`, {})
  }

  //------------------------ Annoucements ------------------------//

  static getAllAnnouncement(params = {}) {
    return Request.make("GET", `/admin/announcements`, {
      params,
    })
  }

  static deleteOneAnnouncement(templateUuid) {
    return Request.make("DELETE", `/admin/announcements/${templateUuid}`, {})
  }

  static createAnnouncementTemplate(body) {
    return Request.make("POST", `/admin/announcements`, body)
  }

  static updateAnnouncementTemplate(templateUuid, body) {
    return Request.make("PUT", `/admin/announcements/${templateUuid}`, body)
  }
}
