<!-- ProgramForm.vue -->
<template>
  <v-card v-if="isDataLoaded">
    <div class="ma-2 pa-5">


      <v-row>
        <v-text-field
          v-model="localProgram.name"
          :label="$t('view.companies.program_name')"
          class="ma-4"
        ></v-text-field>

        <v-text-field
          class="ma-4"
          v-model="localProgram.description"
          :label="$t('view.companies.program_description')"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-col cols="6">
          <op-datepicker
            ref="datepicker"
            :label="$t('label.start_date')"
            :date="localProgram.configuration.start_date"
            :minDate="todayString"
            class="required"
          />
        </v-col>

        <v-col cols="6">
          <op-datepicker
            :label="$t('label.end_date')"
            :date="localProgram.configuration.end_date"
            :minDate="todayString"
          />
        </v-col>
      </v-row>
    </div>


    <v-card-text>
      <div v-for="(campaign, index) in localCampaigns" :key="index">
        <campaign-form
          ref="campaignForms"
          :value="campaign"
          :hatCourses="hatCourses"
          :emailTypes="emailTypes"
          @update="(val) => $set(localCampaigns, index, val)"
        ></campaign-form>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import _ from "lodash"
import moment from "moment"
import CampaignForm from "./CampaignForm.vue"
import CompanyLogic from "@/logics/CompanyLogic"

export default {
  name: "ProgramForm",
  components: {
    CampaignForm,
  },
  props: ["value", "hatCourses", "emailTypes"],
  data: () => ({
    localProgram: null,
    localCampaigns: null,
    loaded: false,
    menu1: false,
    menu2: false,
    panel: [0],
    exercises: [],
    groups: [],
    todayString: new Date().toISOString().substr(0, 10),  // Today's date
  }),
  async mounted() {

    this.groups = await  CompanyLogic.getAllEmployeeGroups(this.companyUuid)


    const program = _.clone(this.value)


    if(this.exercises.length == 0){
      //console.log("program form get exercises")
      //this.exercises = await CompanyLogic.getCompanyPhishingEntities(this.companyUuid, "exercises")
    }
    this.initProgramStart(program)

    let campaigns = _.clone(this.value.campaigns)
    this.initCampaignsStart(program, campaigns)

    this.localProgram = program
    this.localCampaigns = campaigns
  },
  methods: {
    initCampaignsStart(program, campaigns) {
      const programStartMoment = moment(program.configuration.start_date)
      for (let i = 0; i < campaigns.length; i++) {
        campaigns[i].configuration.start_date = programStartMoment
          .add(campaigns[i].configuration.start_days_from_program_start, "days")
          .format("YYYY-MM-DD")
      }
    },
    initProgramStart(program) {
      program.configuration.start_date = moment().format("YYYY-MM-DD")
      program.configuration.end_date = moment()
        .add(program.configuration.default_duration_days, "days")
        .format("YYYY-MM-DD")
    },
    updateCampaignsStart() {
      const campaignForms = this.$refs.campaignForms

      if (!campaignForms) {
        console.error("No campaign forms found")
        return
      }

      for (let i = 0; i < campaignForms.length; i++) {
        campaignForms[i].updateStartDateFromProgramStart(
          this.localProgram.configuration.start_date
        )
      }
    },
    getLocalData() {
      this.localProgram.campaigns = []

      const campaignsForms = this.$refs.campaignForms
      if (!campaignsForms) {
        console.error("No campaign forms found")
        return
      }

      for (let i = 0; i < campaignsForms.length; i++) {
        this.localProgram.campaigns.push(campaignsForms[i].getLocalData())
      }

      return this.localProgram
    },
  },
  computed: {
    companyUuid() {
      return this.$route.params.companyUuid
    },
    isDataLoaded() {
      return (
        this.localProgram &&
        this.localCampaigns &&
        this.groups &&
        this.hatCourses &&
        this.emailTypes &&
        this.exercises
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.panel-content-border {
  border: 1px solid #ccc;
}
</style>
