<template>
  <v-container v-if="user">
    <v-card>
      <v-card-title class="d-flex justify-space-between mb-6">
        <span>{{ user.first_name }} {{ user.last_name }}</span>
        <span>{{ user.email }} </span>
        <v-icon @click="edit()" class="ml-2" elevation="2" icon color="blue"
        >mdi-account-edit
        </v-icon
        >
      </v-card-title>
      <v-card-subtitle></v-card-subtitle>
      <v-card-text>
        <v-data-table
          :headers="roleHeaders"
          :items="roles"
          item-key="role.id"
        ></v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {i18n} from "@/plugins/i18n";

export default {
  name: "oppens-user-access-control",
  props: {
    user: {
      default: null,
    },
  },
  data: () => ({
    localUser: null,
    roleHeaders: [
      {
        text: i18n.t('label.role'),
        align: "start",
        sortable: true,
        filterable: true,
        value: "name",
      },
      {
        text: i18n.t('Slug'),
        align: "start",
        sortable: true,
        filterable: true,
        value: "slug",
      },
      {
        text: i18n.t('Type'),
        align: "start",
        sortable: true,
        filterable: true,
        value: "type",
      },
      {
        text: i18n.t('Perimeter'),
        align: "start",
        sortable: true,
        filterable: true,
        value: "perimeter",
      },
      {
        text: i18n.t('Company'),
        align: "start",
        sortable: true,
        filterable: true,
        value: "company",
      },
    ],
    roles: [],
  }),
  mounted() {
    this.parseAccessControlData()
  },

  methods: {
    parseAccessControlData() {
      this.roles = []
      let index = 0

      // Deep copy of user object
      this.localUser = JSON.parse(JSON.stringify(this.user))

      // Ensure accessControl is defined
      if (!this.localUser.accessControl) return

      const rolesMap = {
        directRoles: "direct role",
        perimetersRoles: "perimeter role",
      }

      // Iterate through directRoles and perimetersRoles
      for (const accessType in rolesMap) {
        if (this.localUser.accessControl[accessType] && Array.isArray(this.localUser.accessControl[accessType])) {
          this.localUser.accessControl[accessType].forEach((item) => {
            index++
            const role = {
              key: index,
              name: item.role.name,
              slug: item.role.slug,
              type: rolesMap[accessType]
            }
            if (accessType === "directRoles") {
              role.company = item.company.business_name
              role.company_uuid = item.company.uuid
            } else if (accessType === "perimetersRoles") {
              role.perimeter = item.perimeter.name
            }
            this.roles.push(role)
          })
        }
      }
    },

    edit() {
      this.$emit("edit", this.localUser)
    },
  },
}
</script>
