import PhishingLogic from "../logics/PhishingLogic"
import utilsMixin from "./utils.mixin"
import { showSnackbar } from "@/services/GlobalActions"
import LearningLogic from "@/logics/LearningLogic"
import { i18n } from "@/plugins/i18n"
import { cloneDeep } from "lodash"

export default {
  mixins: [utilsMixin],
  data: () => ({
    allowedTypes: ["assets"],
    dataList: null,
    type: null,
  }),

  methods: {
    getAllSimulations() {
      return this._withLoading(() =>
        PhishingLogic.getAllSimulations(this.companyUuid)
      )
    },

    getOneSimulation(simulationId) {
      return this._withLoading(() =>
        PhishingLogic.getOneSimulation(this.companyUuid, simulationId)
      )
    },
    saveSimulation(data) {
      return this._withLoading(async () => {
        if (null === data.send_by_date) {
          data.send_by_time = null
        }
        return await PhishingLogic.pushSimulation(this.companyUuid, data)
      })
    },

    stopSimulation(simulationId) {
      return this._withLoading(() =>
        PhishingLogic.stopSimulation(this.companyUuid, simulationId)
      )
    },
    launchSimulation(simulationId) {
      return this._withLoading(() =>
        PhishingLogic.launchSimulation(this.companyUuid, simulationId)
      )
    },
    suppressSimulation(simulationId) {
      return this._withLoading(() =>
        PhishingLogic.deleteSimulation(this.companyUuid, simulationId)
      )
    },
    getSimulationResults(simulation) {
      return this._withLoading(() =>
        PhishingLogic.getSimulationResults(this.companyUuid, simulation.id)
      )
    },
    async createSimulationTest(simulationId) {
      return this._withLoading(() =>
        PhishingLogic.createSimulationTest(this.companyUuid, simulationId)
      )
    },
    async recomputeSimulationResults(simulationId) {
      return this._withLoading(() =>
        PhishingLogic.recomputeSimulationResults(this.companyUuid, simulationId)
      )
    },
    async reportEmail(companyUuid, simulationId, data) {
      return this._withLoading(() =>
        PhishingLogic.reportEmail(companyUuid, simulationId, data)
      )
    },
    // Methods without the loading state:
    async getSimulationCompiledTemplates(companyUuid, simulationId) {
      return await PhishingLogic.getSimulationCompiledTemplates(
        companyUuid,
        simulationId
      )
    },
    async getExerciseTemplateTags(exerciseId) {
      const data = await PhishingLogic.getExerciseTemplateTagsList(exerciseId)
      return Object.keys(data).length > 0 ? data : null
    },
    async getExerciseCompiledWithTemplateTags(exerciseId, templateTags) {
      return await PhishingLogic.getExerciseCompiledWithTemplateTags(
        exerciseId,
        templateTags
      )
    },
    async getExerciseCompiledTemplates(exerciseId) {
      const data = await PhishingLogic.getExerciseCompiledTemplates(exerciseId)
      return Object.keys(data).length > 0 ? data : null
    },
    async getAllSendingProfiles() {
      return this._withLoading(() =>
        PhishingLogic.getAllEntities("sending-profiles")
      )
    },
    async getAllServers() {
      return this._withLoading(() => PhishingLogic.getAllEntities("servers"))
    },
    async getOneServer(serverId) {
      return this._withLoading(() =>
        PhishingLogic.getOneEntity("servers", serverId)
      )
    },
    getAllLandingPages() {
      return this._withLoading(() =>
        PhishingLogic.getAllEntities("landing-pages")
      )
    },
    getAllEmailTemplates() {
      return this._withLoading(() =>
        PhishingLogic.getAllEntities("email-templates")
      )
    },
    buildNewSimulation(companyUuid) {
      const options = { timeZone: "Europe/Paris" }

      return {
        company_uuid: companyUuid,
        name: "",
        launch_date: new Date().toISOString().split("T")[0],
        launch_time: new Date().toLocaleTimeString("en-GB", options),
        send_by_date: null,
        send_by_time: null,
        phishing_exercise_id: null,
        campaign_id: null,
        has_automatic_launch: false,
        has_automatic_stop: false,
        automatic_duration_days: null,
        is_test: false,
        has_phishing_custom_landing_page: false,
        has_phishing_custom_email: false,
        has_phishing_custom_error_page: false,
        has_phishing_custom_sending_profile: false,

        phishing_custom_landing_page: null,
        phishing_custom_email: null,
        phishing_custom_error_page: null,
        phishing_custom_sending_profile: null,
      }
    },
    typeIsAllowed(type) {
      if (!this.allowedTypes.includes(type)) {
        showSnackbar("Type not allowed " + type, "#FF5252")
        return false
      }
      return true
    },
    async confirmDelete(data) {
      if (
        await this.$refs.confirm.open(
          i18n.t("view.companies.confirm_delete"),
          i18n.t("view.companies.confirm_delete_resource_message"),
          i18n.t("buttons.cancel")
        )
      ) {
        await this.deletePhishingResource(this.type, data)
      }
    },
    getPhishingResources(type) {
      if (!this.typeIsAllowed(type)) return

      return this._withLoading(async () => {
        this.dataList = null
        return PhishingLogic.getPhishingResources(type)
      })
    },
    deletePhishingResource(type, data) {
      return this._withLoading(async () => {
        await PhishingLogic.deletePhishingResource(type, data)
        this.loading = false
        this.refresh()
      })
    },
    savePhishingResource(type, data) {
      if (!this.typeIsAllowed(type)) return

      return this._withLoading(async () => {
        const result = await PhishingLogic.savePhishingResource(type, data)
        this.loading = false
        this.refresh()
        return result
      })
    },
    async fetchDirectories() {
      return PhishingLogic.getDirectories()
    },

    async createDirectoryFromAssets(name) {
      return PhishingLogic.createDirectory(name)
    },

    async fetchFiles(directory) {
      return PhishingLogic.getFiles(directory)
    },

    async deleteFile(formData) {
      return PhishingLogic.deleteFile(formData)
    },

    async uploadFileToDirectory(formData) {
      return PhishingLogic.uploadFile(formData)
    },
  },
}
