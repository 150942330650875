<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>

      <v-card-text>
        <p>
          {{ $t("ip_addresses.selected_ip") }}:
          <strong>{{ ip }}</strong>
        </p>

        <!-- Radio group to choose between single IP and IP range -->
        <v-radio-group v-model="mode" row>
          <v-radio
            :label="$t('ip_addresses.blacklist_single_ip')"
            value="single"
          ></v-radio>
          <v-radio
            :label="$t('ip_addresses.blacklist_ip_range')"
            value="range"
          ></v-radio>
        </v-radio-group>

        <!-- Only show this input when the user selects "range" -->
        <v-text-field
          v-if="mode === 'range'"
          v-model="ipRange"
          :label="$t('ip_addresses.enter_ip_range')"
          clearable
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel">
          {{ $t("label.cancel") }}
        </v-btn>
        <v-btn color="red" text @click="confirm">
          {{ $t("label.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "op-ip-blacklist",
  props: {
    // The selected IP to be blacklisted
    ip: {
      type: String,
      required: true,
    },
    // Dialog title, default provided or can be translated
    title: {
      type: String,
      default: "Blacklist IP",
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: this.value,
      mode: "single",
      ipRange: "",
    };
  },
  watch: {
    // Sync the dialog's visibility with the v-model binding.
    value(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit("input", val);
    },
  },
  methods: {
    cancel() {
      this.dialog = false;
      this.$emit("cancel");
    },
    confirm() {
      if (this.mode === "range") {
        // Vérification du format CIDR
        const cidrRegex = /^(25[0-5]|2[0-4]\d|[0-1]?\d?\d)(\.(25[0-5]|2[0-4]\d|[0-1]?\d?\d)){3}\/([0-9]|[1-2]\d|3[0-2])$/;
        if (!cidrRegex.test(this.ipRange)) {
          this.rangeError = this.$t("ip_addresses.error.invalid_cidr_format");
          this.$emit("error", this.$t("ip_addresses.error.invalid_cidr_format"));
          return;
        } else {
          this.rangeError = "";
        }
      }
      this.dialog = false;
      this.$emit("confirmed", {
        mode: this.mode, // 'single' ou 'range'
        ip: this.ip,
        ipRange: this.mode === "range" ? this.ipRange : null,
      });
    },

    confirm2() {
      if (this.mode === "range" && !this.ipRange) {
        this.$emit("error", this.$t("ip_addresses.error.please_enter_ip_range"));
        return;
      }
      this.dialog = false;
      // Emit a confirmed event with the selected mode and data.
      this.$emit("confirmed", {
        mode: this.mode, // 'single' or 'range'
        ip: this.ip,
        ipRange: this.mode === "range" ? this.ipRange : null,
      });
    },
  },
};
</script>

<style scoped>
/* Add any custom styles if needed */
</style>
