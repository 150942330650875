<template>
  <div v-if="simulation">
    <v-card elevation="0">
      <v-card-title class="d-flex justify-space-between">
        <v-spacer></v-spacer>
        <div>
          <v-btn
            color="green"
            class="text-none rounded-lg"
            elevation="0"
            small
            outlined
            @click="refreshData"
          >
            <v-icon left>mdi-refresh</v-icon>
            {{ $t("buttons.recompute_results") }}
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tab" centered icons-and-text>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#summary"> {{ $t("label.summary") }}</v-tab>
          <v-tab href="#timeline"> {{ $t("label.timeline") }}</v-tab>
          <v-tab href="#ipaddresses" v-if="isSystemSupportTeam"> {{ $t("label.ip_addresses") }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="mt-10">

          <!-- summary -->
          <v-tab-item value="summary">

            <!-- summary -->
            <phishing-simulation-summary
              v-on:export="exportResults"
              :simulationIsActive="simulation.is_active"
              :results="simulationResults"
              :serverUrl="serverUrl"
              v-if="simulationResults"
            >
            </phishing-simulation-summary>
          </v-tab-item>

          <!-- timeline -->
          <v-tab-item value="timeline">

            <phishing-simulation-timeline
              :timeline="localTimeline"
              v-on:export="exportTimeline"
              v-if="localTimeline"
            >
            </phishing-simulation-timeline>
          </v-tab-item>

          <!-- ipaddresses -->
          <v-tab-item value="ipaddresses" v-if="isSystemSupportTeam">
            <op-ip-list
              :noSearchBar="true"
              :ips="ips"
              :totalItems="totalIps"
              @refresh="fetchIps"
              @blacklist="handleBlacklist"
              @unblacklist="handleUnblacklist"
            />
          </v-tab-item>


        </v-tabs-items>
      </v-card-text>

      <v-expand-transition></v-expand-transition>
    </v-card>
  </div>
</template>
<script>
import phishingMixin from "@/mixins/phishing.mixin"
import PhishingLogic from "@/logics/PhishingLogic"
import { saveAs } from "file-saver"
import { i18n } from "@/plugins/i18n"
import CompanyLogic from "@/logics/CompanyLogic"
import AuthStore from "@/stores/AuthStore"

export default {
  name: "phishing-simulation-results",
  mixins: [phishingMixin],
  components: {},
  props: {
    simulation: {
      default: null,
    },
    results: {
      default: null,
    },
    serverUrl: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    tab: null,
    reveal: false,
    studentResults: null,
    simulationResults: null,
    isSystemSupportTeam: false,
    sentLabels: [i18n.t("Emails sent")],
    localTimeline: null,
    ips:[],
    totalIps:0,
    paramsCache: null
  }),
  computed: {
    companyUuid() {
      return this.$route.params.companyUuid
    },
  },
  async mounted() {
    this.isSystemSupportTeam = await AuthStore.hasSystemRole("system-support-team")
    this.simulationResults = JSON.parse(JSON.stringify(this.results))
    this.computeTimeline()
  },
  methods: {
    async refreshData() {
      this.simulationResults = await this.recomputeSimulationResults(
        this.simulation.id
      )
      this.computeTimeline()
      this.$emit("refresh")
    },
    computeTimeline() {
      const results = this.simulationResults

      if (!results || !results.results) {
        console.error("no results.results")
        return
      }

      if (results.results.timeline) {
        this.localTimeline = JSON.parse(
          JSON.stringify(results.results.timeline)
        )
      }
    },
    close() {
      this.$emit("close")
    },
    async exportResults() {
      let res = await PhishingLogic.export(
        this.companyUuid,
        this.simulation.id,
        "results"
      )

      let fileName = `${this.simulation.name}-results.csv`
      saveAs(res.data, fileName)
    },
    async exportTimeline() {
      let res = await PhishingLogic.export(
        this.companyUuid,
        this.simulation.id,
        "timeline"
      )
      let fileName = `${this.simulation.name}-timeline.csv`
      saveAs(res.data, fileName)
    },

    async fetchIps(params) {

      if(!params){
        return;
      }

      this.loading = true;
      this.paramsCache = params;
      const { data, meta } = await PhishingLogic.getOneSimulationIpAddresses(
        this.companyUuid,
        this.simulation.id,
        params.page,
        params.itemsPerPage,
        params.sortBy,
        params.sortDesc,
        params.search
      );

      this.ips = data;
      this.totalIps = meta?.total || 0;
      this.loading = false;
    },
    async handleBlacklist(ip) {
      await PhishingLogic.blacklistOneSimulationIp(this.companyUuid, this.simulation.id,ip);
      if (this.paramsCache) {
        this.fetchIps(this.paramsCache);
      }
    },
    async handleUnblacklist(ip) {
      await PhishingLogic.unblacklistOneSimulationIp(this.companyUuid, this.simulation.id, ip);
      if (this.paramsCache) {
        this.fetchIps(this.paramsCache);
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
