<script>
import PhishingLogic from "@/logics/PhishingLogic"
import CompanyLogic from "@/logics/CompanyLogic"
import { cloneDeep, debounce } from "lodash"
import { VueEditor } from "vue2-quill-editor"
import Quill from "quill"
import "quill/dist/quill.snow.css"
import beautify from "js-beautify"
import imageUploadMixin from "@/mixins/imageUploadMixin"

export default {
  props: {
    simulation: Object,
    exerciseTags: Object,
    htmlContentPropName: {
      type: String,
      default: "localCustomEmailHtml",
    },
  },
  components: {
    VueEditor,
  },
  mixins: [imageUploadMixin],
  data() {
    return {
      has_phishing_custom_email: null,
      localTags: null,
      localCustomEmailHtml: "",
      localCustomEmailSubject: "",
      localSimulation: null,
      emailTemplate: null,
      emailSubject: null,
      savedTags: null,
      savedCustomEmailHtml: null,
      savedCustomEmailSubject: null,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ "list": "ordered" }, { "list": "bullet" }],
        ["image", "code-block"],
      ],
      isQuillPopupOpen: false, // Controls the Quill popup
      quillEditorInstance: null,
      quillEditorContentHtml: "", // Data for the Quill editor within the popup
      showDisclaimer: false,
      hasOpenedQuillEditor: false,
      displayTagHelpers: false,
      isHTMLPurifyDialogOpen: false,
      purifiedErrors: [],
      purifiedHtml: "",
      sendingProfile: null,
      customSendingProfile: null,
      has_phishing_custom_sending_profile: null,
    }
  },
  watch: {
    simulation: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          //on prends en compte le changement de simulation que si l'id de l'exercice phishing change ou si c'est la première fois qu'on charge la simulation
          if (!this.localSimulation ||
            this.localSimulation.phishing_exercise_id !== newValue.phishing_exercise_id) {

            this.localSimulation = cloneDeep(newValue)

            if (this.has_phishing_custom_email === null) {
              this.has_phishing_custom_email = newValue.has_phishing_custom_email
            }

            this.getSendingProfile()

            this.switchMode()
          }
        }
      },
    },
    exerciseTags: {
      immediate: true,
      deep: true,
      handler(newTags) {
        this.localTags = cloneDeep(newTags)
        this.refreshTemplate()
      },
    },
    has_phishing_custom_email: "switchMode",
  },
  computed: {
    isFromAddressValid() {
      return this.validateFromAddress(this.customSendingProfile.from_address)
    },
  },
  methods: {
    async switchMode() {
      if (this.has_phishing_custom_email) {

        // Switching to custom HTML mode: Save the current tags
        this.savedTags = cloneDeep(this.localTags)

        if (!this.savedCustomEmailHtml &&
          !this.savedCustomEmailSubject &&
          !this.localSimulation.phishing_custom_email) {
          // Init with phishing exercise template
          await this.fillCustomHtmlFromPhishingExercise()
        } else {
          // If there was previously saved custom HTML, restore it
          this.localCustomEmailHtml = this.savedCustomEmailHtml || this.localSimulation.phishing_custom_email?.html || ""
          this.localCustomEmailSubject = this.savedCustomEmailSubject || this.localSimulation.phishing_custom_email?.subject || ""
        }

        this.emailTemplate = null
      } else {
        // Switching back to standard mode: Save the current custom HTML
        this.savedCustomEmailHtml = this.localCustomEmailHtml
        this.savedCustomEmailSubject = this.localCustomEmailSubject

        // Restore saved tags and recompile the email template with tags
        if (this.savedTags) {
          this.localTags = cloneDeep(this.savedTags)
        }

        await this.refreshTemplate()
      }
    },
    async getSendingProfile() {
      this.has_phishing_custom_sending_profile = this.localSimulation.has_phishing_custom_sending_profile
      this.sendingProfile = await CompanyLogic.getCompanyExerciseSendingProfile(this.localSimulation.company_uuid, this.localSimulation.phishing_exercise_id)

      if (this.localSimulation.phishing_custom_sending_profile) {
        this.customSendingProfile = cloneDeep(this.localSimulation.phishing_custom_sending_profile)
      } else {
        this.customSendingProfile = cloneDeep(this.sendingProfile)
      }

      // Extract the label and email from the 'from_address' field
      if (this.customSendingProfile?.from_address) {
        try {
          const { label, email } = this.extractLabelAndEmail(this.customSendingProfile.from_address)
          this.customFromLabel = label || "" // Editable label field
          this.customFromEmail = email // Email field
        } catch (error) {
          console.error("Invalid 'from_address' format:", error.message)
          this.customFromLabel = "" // Fallback to an empty label
          this.customFromEmail = this.customSendingProfile.from_address // Use as-is
        }
      } else {
        this.customFromLabel = ""
        this.customFromEmail = ""
      }
    },
    extractLabelAndEmail(input) {
      const regex = /^(?:([^<]*)<)?([^<>@]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})>?$/
      const match = input.match(regex)

      if (!match) {
        throw new Error("Invalid email format")
      }

      const label = match[1]?.trim() || "" // Extract the label or set it to an empty string if missing
      const email = match[2] // Extract the email address

      return { label, email }
    },
    combineLabelAndEmail(label, email) {
      return label?.trim() ? `${label.trim()} <${email}>` : email
    },
    async fillCustomHtmlFromPhishingExercise() {
      // we compile the template first then we fill the custom subject/email
      const data = await CompanyLogic.getCompanyExerciseCompiledTemplates(
        this.localSimulation.company_uuid,
        this.localSimulation.phishing_exercise_id,
      )
      const emailTemplate = data?.email
      if (emailTemplate) {
        this.localCustomEmailHtml = this.formatHtml(emailTemplate.html)
        this.localCustomEmailSubject = emailTemplate.subject
      }
    },
    formatCustomHtml() {
      this.localCustomEmailHtml = this.formatHtml(this.localCustomEmailHtml)
    },
    formatHtml(htmlContent) {
      return beautify.html(htmlContent, {
        indent_size: 2,                // Sets indentation size
        wrap_line_length: 0,           // Prevents wrapping by setting line length to zero
        preserve_newlines: false,      // Removes line breaks between tags
        max_preserve_newlines: 0,      // Limits max number of consecutive empty lines to zero
        unformatted: ["a", "span"],    // List tags not to format (modify as needed)
        extra_liners: [],               // Prevents adding extra line breaks around specific tags
      })
    },
    validateFromAddress(address) {
      const nameAddrRegex = /^([^<>]+)?\s*<([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})>$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      return nameAddrRegex.test(address)
    },
    checkFromAddress() {
      if (!this.isFromAddressValid) {
        console.warn("Invalid from address format")
      }
    },
    refreshTemplate: debounce(async function() {
      await this.compileTemplate()
    }, 300),
    async compileTemplate() {
      if (this.has_phishing_custom_email || !this.localSimulation.phishing_exercise_id) return

      try {
        if (!this.localSimulation.company_uuid) {
          console.error("Missing companyUuid ", this.localSimulation)
          return
        }

        const preview = this.localTags
          ? await CompanyLogic.getCompanyExerciseCompiledWithTemplateTags(
            this.localSimulation.company_uuid,
            this.localSimulation.phishing_exercise_id,
            this.localTags,
          )
          : await CompanyLogic.getCompanyExerciseCompiledTemplates(
            this.localSimulation.company_uuid,
            this.localSimulation.phishing_exercise_id,
          )
        if (preview?.email?.html) {
          this.emailTemplate = preview.email.html
        }
        if (preview?.email?.subject) {
          this.emailSubject = preview.email.subject
        }
      } catch (error) {
        console.error("Failed to refresh template:", error)
      }
    },
    onTagInputChange() {
      this.$emit("update-tags", this.localTags)
      this.refreshTemplate()
    },
    openQuillEditor() {
      if (!this.has_phishing_custom_email) {
        console.error("Cannot open Quill editor in standard mode")
        return
      }

      // Show disclaimer only the first time Quill editor is used
      if (!this.hasOpenedQuillEditor) {
        this.showDisclaimer = true
      } else {
        this.openQuillEditorDialog()
      }
    },
    openQuillEditorDialog() {
      this.quillEditorContentHtml = this.localCustomEmailHtml
      this.isQuillPopupOpen = true
    },
    confirmDisclaimer() {
      this.hasOpenedQuillEditor = true
      this.showDisclaimer = false
      this.openQuillEditorDialog()
    },
    saveQuillEditorContent() {
      this.localCustomEmailHtml = this.quillEditorContentHtml // Update main content with sanitized HTML
      this.isQuillPopupOpen = false
    },
    closeQuillEditor() {
      this.isQuillPopupOpen = false
      this.quillEditorInstance = null // Cleanup Quill instance
    },
    async showPurifiedHtml() {
      try {
        const content = await CompanyLogic.purifyHtml(
          this.localSimulation.company_uuid,
          this.localCustomEmailHtml)

        this.purifiedHtml = content.purifiedHtml
        this.purifiedErrors = content.errors

        this.isHTMLPurifyDialogOpen = true

      } catch (error) {
        console.error("Erreur lors de la récupération du contenu purifié :", error)
      }
    },
    validatePurifiedHtml() {
      this.localCustomEmailHtml = this.purifiedHtml
      this.closeHtmlPurifyDialog()
    },
    closeHtmlPurifyDialog() {
      this.isHTMLPurifyDialogOpen = false
    },
    getUpdatedData() {

      if(this.customSendingProfile && this.has_phishing_custom_sending_profile){
        this.customSendingProfile.from_address = this.combineLabelAndEmail(this.customFromLabel, this.customFromEmail)
      }

      const customEmail = this.localSimulation.custom_email || {
        name: "",
        subject: this.localCustomEmailSubject,
        html: this.localCustomEmailHtml,
        attachments: null,
      }

      return {
        has_phishing_custom_email: this.has_phishing_custom_email,
        has_phishing_custom_sending_profile: this.has_phishing_custom_sending_profile,
        exerciseTags: this.localTags,
        phishing_custom_email: customEmail,
        phishing_custom_sending_profile: this.customSendingProfile,
      }
    },
  },
}
</script>

<template>
  <v-card elevation="0" outlined color="#fff">
    <div class="main-container">

      <!-- Sending Profile Section -->
      <v-row align="center" class="mb-4 pa-5">

        <!-- Toggle Custom Sending Profile Mode -->
        <v-switch
          v-model="has_phishing_custom_sending_profile"
          :label="$t('phishingSimulations.edition.customSendingProfile')"
        />

        <!-- Display the Sending Profile Recap -->
        <v-col cols="12" v-if="sendingProfile">
          <div v-if="has_phishing_custom_sending_profile">
            <!--strong class="mb-2">Label: </strong-->
            <span>
            <v-text-field
              clearable
              v-model="customFromLabel"
              dense
              :error-messages="isFromAddressValid ? '' : $t('phishingSimulations.edition.invalidFromAddressFormat')"
              :placeholder="$t('label.enter_custom_from_label')"
              @input="checkFromAddress"
            ></v-text-field>


             <span class="pl-2">
               <strong class="mr-2">Email:</strong> {{ customFromEmail }}
             </span>

            </span>

          </div>
          <div v-else>
            <strong class="mr-2">From:</strong> {{ sendingProfile.from_address }}
          </div>

        </v-col>

      </v-row>


      <!-- Top Controls Section -->
      <v-row align="center" class="mb-4">
        <v-col cols="12" class="d-flex align-center justify-space-between">
          <!-- Toggle Custom Email Mode -->
          <v-switch
            v-model="has_phishing_custom_email"
            :label="$t('phishingSimulations.edition.customEmail')"
          />
          <!-- Main Action Buttons -->
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="showPurifiedHtml"
                       :disabled="!has_phishing_custom_email"
                       class="ma-2"
                       v-bind="attrs"
                       v-on="on">
                  <v-icon color="green">mdi-language-html5</v-icon>
                </v-btn>
              </template>
              <span> Purified HTML</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="fillCustomHtmlFromPhishingExercise"
                       :disabled="!has_phishing_custom_email"
                       class="ma-2"
                       v-bind="attrs"
                       v-on="on">
                  <v-icon color="green">mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("phishingSimulations.edition.loadDefaultHTML") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="openQuillEditor"
                       class="ma-2"
                       :disabled="!has_phishing_custom_email"
                       v-bind="attrs"
                       v-on="on">
                  <v-icon color="green">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("phishingSimulations.edition.openHtmlEditor") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="displayTagHelpers =! displayTagHelpers"
                       class="ma-2"
                       :disabled="!has_phishing_custom_email"
                       v-bind="attrs"
                       v-on="on">
                  <v-icon color="green">mdi-help</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("phishingSimulations.edition.tagsSyntax") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="triggerImageUpload"
                       class="ma-2"
                       :disabled="!has_phishing_custom_email"
                       v-bind="attrs"
                       v-on="on">
                  <v-icon color="green">mdi-image-plus</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("phishingSimulations.edition.imageInsert") }}</span>
            </v-tooltip>

            <input
              ref="imageInput"
              type="file"
              accept="image/*"
              style="display: none"
              @change="handleImageUpload"
            />

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2"
                       @click="formatCustomHtml"
                       :disabled="!has_phishing_custom_email"
                       v-bind="attrs"
                       v-on="on">
                  <v-icon color="green">mdi-format-align-left</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("phishingSimulations.edition.indentCode") }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>

      <!-- Custom Mode Section -->
      <v-row v-if="true === has_phishing_custom_email">
        <!-- Preview Section -->
        <v-col cols="6">
          <div class="preview-container">
            <div class="text-h3 mb-5">{{ $t("phishingSimulations.preview.preview") }}</div>
            <v-card-text class="font-weight-bold frame mb-2">
              {{ $t("phishingSimulations.preview.emailObject", { object: localCustomEmailSubject }) }}
            </v-card-text>
            <iframe :srcdoc="localCustomEmailHtml" class="frame with-subject full-size"></iframe>
          </div>
        </v-col>

        <!-- Editor Section -->
        <v-col cols="6">
          <div class="text-h3 mb-5">{{ $t("phishingSimulations.preview.editable_content") }}</div>
          <v-text-field label="Custom Subject" v-model="localCustomEmailSubject" outlined
                        placeholder="Enter custom subject here"></v-text-field>
          <v-textarea
            @click="updateCursorPosition"
            @keyup="updateCursorPosition"
            label="Raw HTML"
            v-model="localCustomEmailHtml"
            rows="20"
            class="responsive-textarea"
            outlined
            placeholder="Edit HTML directly"></v-textarea>
        </v-col>
      </v-row>

      <!-- Standard Mode Section (with tags) -->
      <v-row v-else>
        <v-col cols="8" class="mt-4">
          <div class="preview-container">
            <div class="text-h3 mb-5">{{ $t("phishingSimulations.preview.preview") }}</div>
            <v-card-text class="font-weight-bold frame mb-2">
              {{ $t("phishingSimulations.preview.emailObject", { object: emailSubject }) }}
            </v-card-text>
            <iframe name="emailIframe" :srcdoc="emailTemplate" id="preview-iframe"
                    class="frame with-subject email-iframe full-size"></iframe>
          </div>
        </v-col>

        <v-col cols="4" class="mt-4">
          <div class="text-h3 mb-5">{{ $t("view.companies.editable_tags") }}</div>
          <v-form v-if="localTags?.email?.tags" class="pl-2 pr-2">
            <div v-for="(value, key, index) in localTags.email.tags" :key="index">
              <v-text-field :label="key" :id="`emailIframe-${key}`" v-model="localTags.email.tags[key].value" outlined
                            @input="onTagInputChange"></v-text-field>
            </div>
          </v-form>
          <v-card-text v-else type="info" class="mt-2">
            {{ $t("view.companies.template_contains_no_tags") }}
          </v-card-text>
        </v-col>
      </v-row>


    </div>

    <!-- Quill Editor Popup -->
    <v-dialog v-model="isQuillPopupOpen" max-width="800px">
      <v-card>
        <v-card-text class="pt-4">
          <vue-editor v-model="quillEditorContentHtml" ref="htmlEditor" :editorToolbar="customToolbar"></vue-editor>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveQuillEditorContent">{{ $t("buttons.save") }}</v-btn>
          <v-btn @click="closeQuillEditor">{{ $t("buttons.cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Disclaimer Dialog -->
    <v-dialog v-model="showDisclaimer" max-width="600px">
      <v-card>
        <v-card-title>{{ $t("phishingSimulations.edition.disclaimerTitle") }}</v-card-title>
        <v-card-text>{{ $t("phishingSimulations.edition.quillDisclaimerMessage") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="confirmDisclaimer">{{ $t("buttons.continue") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Tag Syntax Help Dialog -->
    <v-dialog v-model="displayTagHelpers" max-width="800px">
      <v-card>
        <v-card-text class="pt-4">
          <TagHelpCard :noTags="true" :displayTracker="true"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="displayTagHelpers = false">{{ $t("buttons.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isHTMLPurifyDialogOpen" max-width="1400px">
      <v-card>
        <v-card-title>
          <span>Prévisualisation du contenu purifié</span>
        </v-card-title>

        <v-card-text>
          <div class="errors-section">
            <h4>Erreurs détectées :</h4>
            <template v-if="purifiedErrors.length > 0">
              <ul>
                <li v-for="(error, index) in purifiedErrors" :key="index">
                  Ligne {{ error[0] }}, Colonne {{ error[1] }} : {{ error[2] }}
                </li>
              </ul>
            </template>
            <p v-else>Aucune erreur détectée. 🎉</p>
          </div>
          <div>
            <h4>Contenu HTML Purifié :</h4>
            <iframe
              :srcdoc="purifiedHtml"
              style="width: 100%; height: 300px; border: 1px solid #ccc;">
            </iframe>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="validatePurifiedHtml">Valider</v-btn>
          <v-btn color="grey" @click="closeHtmlPurifyDialog">Annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style scoped>
.text-h3 {
  font-size: 1.15rem !important;
  font-weight: 500;
}

.frame {
  border: 1px dotted #e0e0e0;
  background-color: #fff;
}

.full-size {
  width: 100%;
  height: calc(90% - 100px);
  overflow: auto;
}

.main-container {
  padding: 10px;
}

.preview-container {
  height: calc(100vh - 70px - 52px - 12px);
}
</style>
