<template>
  <div>
    <div class="d-flex justify-end mb-4">
      <v-btn
        color="orange"
        class="text-none rounded-lg"
        elevation="0"
        small
        outlined
        @click="exportData"
      >
        <v-icon left>mdi-download</v-icon>
        {{ $t("buttons.export") }}
      </v-btn>
    </div>
    <div v-if="localTimeline">
      <v-data-table
        :headers="headers"
        :footer-props="footerProps"
        :items="localTimeline"
        :items-per-page="5"
        class="elevation-1"
      ></v-data-table>
    </div>
  </div>


</template>
<script>
import { i18n } from "@/plugins/i18n"

export default {
  name: "phishing-simulation-timeline",
  props: {
    timeline: {
      default: null,
    },
  },
  data: () => ({
    localTimeline: null,
    footerProps: {
      "items-per-page-options": [25, 50, 100, -1],
    },
    headers: [
      {
        text: i18n.t("label.event"),
        align: "start",
        sortable: true,
        value: "message",
      },
      {
        text: i18n.t("label.time"),
        align: "start",
        sortable: true,
        value: "time",
      },
      {
        text: i18n.t("label.employee"),
        align: "start",
        sortable: true,
        value: "employee.email",
      },
      {
        text: i18n.t("label.excluded"),
        align: "start",
        sortable: true,
        value: "is_excluded",
      },
    ],
  }),
  mounted() {
    this.updateTimeline(this.timeline)
  },
  watch: {
    timeline: {
      handler(newVal, oldVal) {
        this.updateTimeline(newVal)
      },
    },
  },
  methods: {
    async exportData() {
      this.$emit("export")
    },
    updateTimeline(timeline) {
      const data = []
      for (let i = 0; i < timeline.length; i++) {
        if (timeline[i] && !timeline[i].employee) {
          timeline[i].employee = {
            email: timeline[i].email,
          }
        }
        if (timeline[i]) {
          data.push(timeline[i])
        }
      }

      this.localTimeline = data
    },
  },
}
</script>
