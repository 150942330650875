<template>
  <div v-if="data">
    <v-card elevation="0" outlined class="ma-1">
      <v-card-title>{{ data.name }}</v-card-title>
      <v-card-subtitle>
        <div>{{ $t("view.learning_phishing.subject") }} : {{ data.subject }}</div>
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" small class="text-none rounded-lg" @click="duplicate()">{{ $t("buttons.duplicate") }}</v-btn>
        <v-btn elevation="2" small class="text-none rounded-lg" @click="edit()">{{ $t("buttons.edit") }}</v-btn>
        <v-btn elevation="2" small class="text-none rounded-lg" dark color="green" @click="viewCode()"
        >{{ $t("buttons.show") }}
        </v-btn
        >
        <v-icon @click="suppress()" small class="ml-2" elevation="2" icon
        >mdi-delete
        </v-icon
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "phishing-email-template",
  data: () => ({
    reveal: false,
  }),
  props: {
    data: {
      default: null,
    },
  },
  methods: {
    viewCode() {
      this.$emit("view", this.data.id)
    },
    async suppress() {
      this.$emit("suppress", this.data)
    },
    edit() {
      this.$emit("edit", this.data)
    },
    duplicate() {
      this.$emit("duplicate", this.data)
    },
  },
}
</script>
