<template>
  <div style="margin-top: 20px">
    <op-confirmation-dialog ref="confirm" :showButton="true" />
    <v-row id="preset-list">
      <v-col
        v-for="(preset, index) in presetsArray"
        :key="index"
        cols="12"
        md="4"
      >
        <v-card
          class="campaign-card"
          @click="instantiate(preset)"
          :id="'preset-card-' + index"
        >
          <v-card-title :id="'preset-title-' + index">
            {{ preset.name[lang] }}
          </v-card-title>
          <v-card-subtitle :id="'description-' + index">
            {{ preset.description[lang] || "&nbsp;" }}
          </v-card-subtitle>
          <v-img
            :src="preset.illustration || defaultImage"
            height="200px"
            class="card-image"
            :id="'preset-image-' + index"
          ></v-img>
          <v-card-title class="text-h6" :id="'preset-campaigns-' + index">
            {{ preset.campaigns.length }}
            {{
              preset.campaigns.length > 1
                ? $t("view.companies.campaigns")
                : $t("view.companies.campaign")
            }}
          </v-card-title>
          <v-card-text>
            <v-chip class="ma-2" color="primary" text-color="white" :id="'preset-courses-' + index">
              <v-icon left>mdi-school</v-icon>
              {{ preset.mergedCampaign.company_courses.length }}
              {{
                preset.mergedCampaign.company_courses.length > 1
                  ? $t("view.companies.learnings")
                  : $t("view.companies.learning")
              }}
            </v-chip>
            <v-chip class="ma-2" color="secondary" text-color="white" :id="'preset-simulations-' + index">
              <v-icon left>mdi-hook</v-icon>
              {{ preset.mergedCampaign.simulations.length }}
              {{
                preset.mergedCampaign.simulations.length > 1
                  ? $t("view.companies.simulations")
                  : $t("view.companies.simulation")
              }}
            </v-chip>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>



<script>
import _ from "lodash"
import moment from "moment"
import { i18n } from "@/plugins/i18n"

export default {
  name: "program-preset-card",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    lang: {
      type: String,
      default: "en",
    },
  },
  data: () => ({
    presetsArray: [],
    defaultImage: require("@/assets/default-backg.png"),
    todayString: new Date().toISOString().substr(0, 10),
  }),
  async mounted() {
    this.presetsArray = Object.values(this.data)

    this.presetsArray.forEach((preset) => {
      this.initProgramStart(preset)
      let campaigns = _.clone(preset.campaigns)
      this.initCampaignsStart(preset, campaigns)
      campaigns.forEach((campaign) => {
        this.initCoursesConfiguration(campaign)
        this.initSimulationsConfiguration(campaign)
      })
      this.mergeCampaigns(preset, campaigns)
    })
    this.lang = this.lang === "fr" ? "fr" : "en"
    this.$nextTick(() => {
      this.adjustDescriptionHeights()
    })
  },
  methods: {
    initSimulationsConfiguration(campaign) {
      if (campaign.simulations && campaign.simulations.length > 0) {
        const campaignStartMoment = moment(campaign.configuration.start_date)
        campaign.simulations.forEach((sim) => {
          if (sim.configuration) {
            sim.configuration.launch_date = campaignStartMoment
              .add(sim.configuration.start_days_from_campaign_start, "days")
              .format("YYYY-MM-DD")
          }
        })
      }
    },
    initCoursesConfiguration(campaign) {
      if (campaign.company_courses && campaign.company_courses.length > 0) {
        const campaignStartMoment = moment(campaign.configuration.start_date)
        campaign.company_courses.forEach((course) => {
          if (course.configuration) {
            course.configuration.start_date = campaignStartMoment
              .add(course.configuration.start_days_from_campaign_start, "days")
              .format("YYYY-MM-DD")
          }
        })
      }
    },
    initCampaignsStart(program, campaigns) {
      if (campaigns && campaigns.length > 0) {
        const programStartMoment = moment(program.configuration.start_date)
        campaigns.forEach((campaign) => {
          if (campaign.configuration) {
            campaign.configuration.start_date = programStartMoment
              .add(campaign.configuration.start_days_from_program_start, "days")
              .format("YYYY-MM-DD")
          }
        })
      }
    },
    initProgramStart(program) {
      if (program.configuration) {
        program.configuration.start_date = moment().format("YYYY-MM-DD")
        program.configuration.end_date = moment()
          .add(program.configuration.default_duration_days, "days")
          .format("YYYY-MM-DD")
      }
    },
    mergeCampaigns(preset, campaigns) {
      if (campaigns && campaigns.length > 0) {
        preset.mergedCampaign = {
          company_courses: [],
          simulations: [],
        }
        campaigns.forEach((campaign) => {
          if (campaign.company_courses) {
            preset.mergedCampaign.company_courses.push(
              ...campaign.company_courses
            )
          }
          if (campaign.simulations) {
            preset.mergedCampaign.simulations.push(...campaign.simulations)
          }
        })
      }
    },
    async instantiate(preset) {
      if (
        await this.$refs.confirm.open(
          i18n.t("Confirmation of creation"),
          `${i18n.t(
            "Are you sure you want to create this program?"
          )}<br><br><i style="color: red; font-size: 0.8em;">${i18n.t(
            "Remember to customise simulations and pre-configured training, including launch dates and the groups of employees concerned"
          )}</i>`,
          i18n.t("buttons.cancel")
        )
      ) {
      const transformedPreset = this.transformPresetForBackend(preset);
      this.$emit("instantiate", transformedPreset);
      }
    },

    transformPresetForBackend(preset) {
      const transformedPreset = { ...preset }
      transformedPreset.name = preset.name[this.lang]
      transformedPreset.description = preset.description[this.lang]
      delete transformedPreset.mergedCampaign
      return transformedPreset
    },

    adjustDescriptionHeights() {
      let maxHeight = 0

      this.presetsArray.forEach((_, index) => {
        const descriptionElement = this.$refs[`description-${index}`][0]
        if (descriptionElement) {
          const height = descriptionElement.offsetHeight
          if (height > maxHeight) {
            maxHeight = height
          }
        }
      })

      this.presetsArray.forEach((_, index) => {
        const descriptionElement = this.$refs[`description-${index}`][0]
        if (descriptionElement) {
          descriptionElement.style.height = `${maxHeight}px`
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
$color: #1876d2;

h4 {
  margin-bottom: 5px;
  border-bottom: $color dashed 1px;
  color: $color;
  text-transform: capitalize;
  font-size: 0.9rem;
  i {
    font-size: 0.7rem;
    color: #999;
  }
}

.title {
  font-size: 1.1rem;
  font-weight: 500;
}

.subtitle {
  font-style: italic;
  color: #959595;
  font-size: 0.9rem;
}

.campaign-card {
  padding: 6px;
  font-size: 0.85rem;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
}

.course-card,
.simulation-card {
  border-left: 3px solid $color;
  background-color: #f9f9f9;
  border-radius: 6px;
  font-size: 0.85rem;
}
</style>
