import CompanyLogic from "@/logics/CompanyLogic"
import utilsMixin from "./utils.mixin"                                      

export default {
  mixins: [utilsMixin],
  data: () => ({
  }),
  methods: {
    async FetchCompanySubscription(companyUuid) {
      const subscription = await this.getSubscription(companyUuid)
      if (subscription) {
        const start_date = new Date(subscription.start_date)
        const end_date = new Date(subscription.end_date)
        const formattedStartDate = start_date.toLocaleDateString("fr-FR")
        const formattedEndDate = end_date.toLocaleDateString("fr-FR")
        subscription.start_date = formattedStartDate
        subscription.end_date = formattedEndDate
        return subscription
      }
      return null
    },
    async FetchCompanyBalance(companyUuid) {
       const balance = await this.getBalance(companyUuid)
       return balance
    },
    getSubscription(companyUuid) {
      return this._withLoading(() => CompanyLogic.getSubscription(companyUuid))
      },
    
      getBalance(companyUuid) {
      return this._withLoading(() => CompanyLogic.getBalance(companyUuid))
    },
      getCreditConsumptionHistory(companyUuid) {
      return this._withLoading(() => CompanyLogic.getCreditConsumptionHistory(companyUuid))
    },
      getDetailedCreditConsumptionHistory(companyUuid,year,month) {
      return this._withLoading(() => CompanyLogic.getDetailedCreditConsumptionHistory(companyUuid,year,month))
    }
  },
}
