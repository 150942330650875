<script>
import creditConsumptionMixin from "@/mixins/creditConsumption.mixin"
import CompanyLogic from "@/logics/CompanyLogic"
import { showSnackbar } from "@/services/GlobalActions"
import { i18n } from "@/plugins/i18n"

export default {
  name: "company-subscription-edition",
  mixins: [creditConsumptionMixin],
  props: {
    data: {
      default: null,
    },
  },
  data: function() {
    return {
      subscription: null,
      subscriptionType: "",
      balance: null,
      balance_amount: 0,
      localData: null,
      isSystemSupportTeam: false,
      newSubscription: null,
      subscriptionTypes: [
        "msp1",
        "prepaid",
      ],
      selectedCreditAmount: null,
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date().toISOString().split("T")[0],
    }
  },
  computed: {
    canPurchaseCredits() {
      return this.subscription && this.subscription.subscription_type === "prepaid"
    },
  },
  async mounted() {
    this.initNewSubscription()
    this.localData = JSON.parse(JSON.stringify(this.data))
    this.retrieveCompanyInfos()
  },
  watch: {
    data(newValue, oldValue) {
      this.localData = JSON.parse(JSON.stringify(newValue))
      this.retrieveCompanyInfos()
    },
  },
  methods: {
    async retrieveCompanyInfos() {

      if (!this.localData.uuid) return

      this.subscription = await this.FetchCompanySubscription(this.localData.uuid)
      if (!this.subscription) {
        this.initNewSubscription()
      } else {
        this.subscriptionType = this.subscription.subscription_type
        this.balance = await this.FetchCompanyBalance(this.localData.uuid)
        this.balance_amount = this.balance?.credit_balance
      }
    },
    async updateSubscription() {
      // Vérification côté client
      if (!["prepaid", "monthly", "yearly", "msp1"].includes(this.subscriptionType)) {
        showSnackbar(i18n.t("credit_management.invalid_subscription_type"), "#FF5252")
        return
      }
      const payload = {
        subscription_type: this.subscriptionType,
        start_date: this.start_date,
        end_date: this.end_date,
        has_automatic_renewal: this.newSubscription.has_automatic_renewal,
      }
      try {
        await CompanyLogic.updateSubscriptionType(this.localData.uuid, payload)
        showSnackbar(i18n.t("messages.successful_update"))
      } catch (error) {
        console.error(error)
      } finally {
        this.retrieveCompanyInfos()
      }
    },
    async purchaseCredits() {
      const amount = parseInt(this.selectedCreditAmount, 10)
      if (!amount || amount <= 0) {
        showSnackbar("Veuillez entrer un montant entier positif")
        return
      }
      const payload = {
        credit_amount: this.selectedCreditAmount,
      }
      try {
        await CompanyLogic.purchaseCredits(this.localData.uuid, payload)
        this.balance = await this.FetchCompanyBalance(this.localData.uuid)
        this.balance_amount = this.balance?.credit_balance
        this.selectedCreditAmount = null
        showSnackbar(i18n.t("messages.successful_purchase"));

      } catch (error) {
        console.error(error)
      }
    },
    updateStartDate(date) {
      this.newSubscription.start_date = date
      this.start_date = date
    },
    updateEndDate(date) {
      this.newSubscription.end_date = date
      this.end_date = date
    },
    initNewSubscription() {
      // Instancier une nouvelle subscription pour afficher le formulaire
      const start_date = new Date().toISOString().split("T")[0]
      const endDate = new Date()
      endDate.setFullYear(endDate.getFullYear() + 1)
      const end_date = endDate.toISOString().split("T")[0]
      this.newSubscription = {
        subscription_type: "",
        start_date: start_date,
        end_date: end_date,
        duration_in_days: "",
        has_automatic_renewal: false,
      }
      this.start_date = start_date
      this.end_date = end_date
      // On vide la subscription existante pour afficher le formulaire
      this.subscription = null
      this.subscriptionType = ""
    },
    cancel() {
      this.retrieveCompanyInfos()
    },
  },
}
</script>
<template>
  <div class="bloc" v-if="localData">

    <!-- Affichage de la carte résumé si un abonnement existe -->
    <div v-if="subscription && subscription.subscription_type">
      <span class="sectionTitle mb-3">{{ $t("credit_management.ongoing_subscription") }}</span>
      <div class="card">
        <div><strong>{{ $t("label.type") }} :</strong> {{ subscription.subscription_type }}</div>
        <div><strong>{{ $t("label.start_date") }} :</strong> {{ subscription.start_date }}</div>
        <div><strong>{{ $t("label.end_date") }} :</strong> {{ subscription.end_date }}</div>
        <div><strong>{{ $t("label.duration") }} ({{ $t("label.days") }}) :</strong> {{ subscription.duration_in_days }}
        </div>
        <div class="actions">
          <v-btn small @click="initNewSubscription" :disabled="!localData.is_msp">{{ $t("credit_management.new_subscription") }}</v-btn>
        </div>
      </div>
    </div>

    <div v-else>
      <span class="sectionTitle mb-3">{{ $t("credit_management.edit_subscription") }}</span>
      <v-select
        v-model="subscriptionType"
        :items="subscriptionTypes"
        :label="$t('credit_management.subscription_type')"
      />
      <op-datepicker
        ref="datepicker"
        :label="$t('label.start_date')"
        :date="start_date"
        @update="updateStartDate"
      />
      <op-datepicker
        ref="datepicker"
        :label="$t('label.end_date')"
        :date="end_date"
        @update="updateEndDate"
      />
      <div class="actions">
        <v-btn small class="mr-2" @click="cancel">{{ $t("buttons.cancel") }}</v-btn>
        <v-btn small @click="updateSubscription">{{ $t("buttons.validate") }}</v-btn>
      </div>
    </div>

    <!-- Balance de crédits -->
    <div class="balance mb-5">
      <span class="sectionTitle">{{ $t("credit_management.available_credits") }} : </span>
      <span class="value">{{ balance_amount }} </span>
    </div>

    <!-- Achat de crédits -->
    <div v-if="canPurchaseCredits">
      <span class="sectionTitle mb-3">{{ $t("credit_management.credits_purchase") }}</span>
      <v-text-field
        v-model="selectedCreditAmount"
        :label="$t('label.credit_amount')"
        type="number"
        min="1"
      />
      <div class="actions">
        <v-btn small
               color="green"
               dark
               @click="purchaseCredits">{{ $t("buttons.purchase") }}
        </v-btn>
      </div>
    </div>

  </div>
</template>
<style scoped>

.sectionTitle {
  font-weight: bold;
  font-size: 1.2rem;
  color: #2a2929;
}

.value {
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  font-family: monospace;
  color: #1cc8aa;
}


.bloc {
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 20px;
  margin: 11px;
}

.bloc-title {
  font-weight: bold;
  text-align: center;
  font-size: 1.5em;
  color: #2a2929;
}

.actions {
  text-align: right;
}

</style>
