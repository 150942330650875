<template>
  <div v-if="localProgram">
    <v-card elevation="0" outlined color="#fafafa">
      <v-card-text>
        <v-text-field
          id="program-name"
          :label="$t('view.companies.program_name')"
          v-model="localProgram.name"
          class="required"
        >
        </v-text-field>

        <op-datepicker
          id="program-start"
          :label="$t('view.companies.program_start')"
          :date="localProgram.start_date"
          v-on:update="updateStart"
          class="required"
        >
        </op-datepicker>

        <op-datepicker
          id="program-end"
          :label="$t('view.companies.program_end')"
          :date="localProgram.end_date"
          v-on:update="updateEnd"
          class="required"
        >
        </op-datepicker>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" class="text-none rounded-lg" id="confirm-button"
        >{{ $t("buttons.save") }}
        </v-btn
        >
        <v-btn elevation="2" @click="close()" class="text-none rounded-lg">{{ $t("buttons.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "oppens-program-edition",
  props: {
    program: {
      default: null,
    },
  },
  data: function () {
    return {
      localProgram: null,
    }
  },
  mounted() {
    this.localProgram = JSON.parse(JSON.stringify(this.program))
  },
  computed: {
    form() {
      return this.$refs.form
    },
  },
  methods: {
    async submit() {
      this.$emit("save", this.localProgram)
    },
    close() {
      this.$emit("close")
    },
    updateStart(data) {
      this.localProgram.start_date = data
    },
    updateEnd(data) {
      this.localProgram.end_date = data
    },
  },
}
</script>
