<template>
  <div v-if="group">
    <v-card elevation="0" outlined>
      <v-card-title class="d-flex justify-space-between mb-6">
        <v-row>
          <v-col
          cols="8"
          class="text-capitalize d-flex align-center justify-start"
          >
            <span class="mr-2">{{ group.name }}</span>
            <v-chip small label dark color="yellow" v-if="group.is_smart" class="mr-2">
              <v-icon>mdi-lightbulb-on</v-icon>
            </v-chip>


            <v-tooltip slot="append" bottom>
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" small dark color="#1ac8aa">
                  <v-icon>mdi-account-multiple</v-icon>
                  {{ group.employees.length }}
                </v-chip>
              </template>
              <span>{{ $t("view.companies.nb_employees") }}</span>
            </v-tooltip>
          </v-col>

          <v-col cols="4" class="d-flex justify-end flex-column align-end">
            <span class="small-text"> {{ $t("view.companies.last_change") }}: {{ group.last_employee_list_update_date }} </span>
          </v-col>

        </v-row>
      </v-card-title>
      <v-card-subtitle v-if="group.description">
        {{ group.description }}
      </v-card-subtitle>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" class="text-none rounded-lg" @click="toggleEmployees()">{{ $t("view.dashboard.employees") }}</v-btn>
        <v-btn
          elevation="2"
          class="text-none rounded-lg"
          @click="refreshSmartGroup()"
          v-if="group.is_smart"
        >{{ $t("buttons.refresh") }}
        </v-btn
        >

        <v-btn elevation="2" class="text-none rounded-lg" @click="edit()">{{ $t("buttons.edit") }}</v-btn>

        <v-btn
          elevation="2"
          class="text-none rounded-lg"
          @click="manualSuppresion()"
          v-if="group.is_smart"
        >{{ $t("buttons.manual_deletion") }}
        </v-btn
        >

        <v-icon @click="suppress()" class="ml-2" elevation="2" icon
        >mdi-delete
        </v-icon>
      </v-card-actions>
      <v-card-text>
        <div v-if="manualSuppression">
          <v-data-table
            :headers="shortHeadersWithActions"
            :items="group.employees"
            :single-select="true"
            item-key="id"
            class="elevation-1"
            :search="search"
            :item-class="itemRowBackground"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="deleteEmployee(item)"> mdi-delete</v-icon>
            </template>
          </v-data-table>
        </div>

        <div v-if="revealEmployees && group.employees">
          <v-data-table
            :headers="shortHeaders"
            :items="group.employees"
            :single-select="false"
            item-key="id"
            class="px-4 mt-5"
            :search="search"
            :item-class="itemRowBackground"
          >
          </v-data-table>
        </div>
      </v-card-text>
      <!--v-expand-transition>
        <oppens-employee-group-edition
          v-if="reveal"
          :group="group"
          v-on:save="save"
          v-on:close="close"
        >
        </oppens-employee-group-edition>
      </v-expand-transition-->
    </v-card>
  </div>
</template>
<script>
import employeeTableMixin from "@/mixins/employeeTable.mixin"
import employeesMixin from "@/mixins/employees.mixin"

export default {
  name: "oppens-employee-group",
  mixins: [employeeTableMixin, employeesMixin],
  data: () => ({
    localGroup: null,
    reveal: false,
    revealEmployees: false,
    manualSuppression: false,
  }),
  props: {
    group: {
      default: null,
    },
    companyUuid: {
      default: null,
    },
  },
  methods: {
    edit() {
      if (this.group.is_smart) {
        this.$emit("editSmart", this.group)
        return
      }

      this.$emit("edit", this.group)
     // this.revealEmployees = false
      //this.reveal = true
    },
    manualSuppresion() {
      //check if the group rules enable manual suppression
      this.reveal = false
      this.revealEmployees = false
      this.manualSuppression = true
    },
    async deleteEmployee(item) {
      const values = {
        employeeId: item.id,
        groupId: this.group.id,
      }

      const group = await this.deleteOneSmartGroupEmployee(
        values.groupId,
        values.employeeId
      )
      if (group) {
        this.group = group
      }
    },
    save(data) {
      this.$emit("save", data)
      this.reveal = false
    },
    suppress() {
      this.$emit("suppress", this.group.id)
      this.reveal = false
    },
    close() {
      this.reveal = false
    },
    toggleEmployees() {
      this.revealEmployees = !this.revealEmployees
      if (this.revealEmployees) {
        this.manualSuppression = false
      }
    },
    refreshSmartGroup() {
      this.$emit("refreshSmart", this.group)
    },
  },
}
</script>
<style lang="scss" scoped>
.small-text {
  font-size: 12px;
  color: grey;
}
.name {
  min-width: 140px;
  color: black;
  display: inline-block;
  font-weight: bold;
}

.email {
  min-width: 250px;
  display: inline-block;
  margin-right: 10px;
}
</style>
