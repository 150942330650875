<template>
  <v-combobox
    v-model="currentValue"
    :items="filteredTimezones"
    :label="label"
    clearable
    :disabled="disabled"
    :search-input.sync="search"
  />
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "op-timezone-selector",
  props: {
    // Expect a simple string value (e.g., "Europe/Paris")
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: "Select Timezone"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: "",
      // Full list of timezone names from moment-timezone
      timezones: moment.tz.names()
    };
  },
  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        // Emit the new value when updated (an empty string when cleared)
        this.$emit("input", newVal);
      }
    },
    filteredTimezones() {
      if (this.search) {
        return this.timezones.filter(tz =>
          tz.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.timezones;
    }
  }
};
</script>

<style scoped>
/* Optional: Add custom styling if needed */
</style>
