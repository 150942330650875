<!-- SimulationForm.vue -->
<template>
  <v-expansion-panels v-if="localSimulation" class="ma-1" v-model="panel" multiple>
    <v-expansion-panel>

      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t("view.companies.simulation_name") }}</v-list-item-title>
            <v-list-item-subtitle>{{ localSimulation.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t("label.launch_date") }}</v-list-item-title>
            <v-list-item-subtitle>{{ localSimulation.configuration.launch_date }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t("label.launch_time") }}</v-list-item-title>
            <v-list-item-subtitle>{{ localSimulation.configuration.launch_time }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t("label.automatic_launch") }}</v-list-item-title>
            <v-list-item-subtitle>{{ localSimulation.automation.automatic_launch ? "Yes" : "No" }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t("label.simulation_automatic_stop") }}</v-list-item-title>
            <v-list-item-subtitle>{{ localSimulation.automation.automatic_stop ? "Yes" : "No" }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t("label.automatic_duration_days") }}</v-list-item-title>
            <v-list-item-subtitle>{{ localSimulation.automation.automatic_duration_days ? "Yes" : "No" }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-expansion-panel>
  </v-expansion-panels>
</template>


<script>
import _ from "lodash"
import companiesPhishingMixin from "@/mixins/companies.phishing.mixin";
import phishingMixin from "@/mixins/phishing.mixin";

export default {
  mixins: [
    companiesPhishingMixin,
    phishingMixin,
  ],

  name: "simulation-form",

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    localSimulation: null,
    panel: [0],
    showModalExercice: false,
    selectedExercise: {},
  }),

  async mounted() {
    this.localSimulation = _.clone(this.value)
  },

  methods: {
    getLocalData() {
      return this.localSimulation
    },
  },

  computed: {
    companyUuid() {
      return this.$route.params.companyUuid
    }
  },
}
</script>

<style lang="scss" scoped>
$color: #1876d2;
$color2: #000;

h4 {
  margin-bottom: 20px;
  border-bottom: $color dashed 1px;
  color: $color;
  text-transform: capitalize;

  i {
    font-size: 0.8em;
    color: #999;
  }
}

h5 {
  width: 100%;
  margin-bottom: 20px;
  border-bottom: $color2 solid 1px;
  color: $color2;
  text-transform: capitalize;

  i {
    font-size: 0.8em;
    color: #999;
  }
}

.panel-content-border {
  border: 1px solid #ccc;
}
</style>
