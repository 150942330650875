<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    eager
    @keydown.esc="close"
  >
    <v-card elevation="0" outlined color="#fafafa" v-if="localGroup">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          class="body-2 font-weight-bold text-none rounded-lg"
          @click.native="close"
        >{{ $t("view.companies.give_up") }}
        </v-btn>
        <v-btn
          @click="submit()"
          outlined
          color="green"
          class="body-2 font-weight-bold text-none rounded-lg"
          >{{ $t("buttons.save") }}
        </v-btn>
      </v-card-actions>

      <v-card-text>
        <v-text-field
          :label="$t('view.companies.group_name')"
          v-model="localGroup.name"
          class="required"
        >
        </v-text-field>

        <v-text-field
          :label="$t('view.companies.group_description')"
          v-model="localGroup.description"
        >
        </v-text-field>

        <op-employee-list
          ref="opEmployeeList"
          :employees="collaborators"
          :selectedEmployeesIds="selectedEmployeesIds"
          :totalEmployees="totalEmployees"
          :errorMessage="errorMessage"
          :companyUuid="companyUuid"
          :display-import="false"
          :display-add="false"
          :display-menu="false"
          v-on:refresh-employees="loadEmployees"
        ></op-employee-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import EmployeeLogic from "@/logics/EmployeeLogic"

export default {
  name: "op-employee-group-edition",
  props: {
    group: {
      default: null,
    },
  },
  provide: function () {
    return {
      deleteOneEmployee: EmployeeLogic.deleteOne,
      updateOneEmployee: EmployeeLogic.updateOne,
      createOneEmployee: EmployeeLogic.createOne,
      createEmployeeList: EmployeeLogic.createList,
      activateEmployeeList: EmployeeLogic.activateList,
      deactivateEmployeeList: EmployeeLogic.deactivateList,
    }
  },
  data: function () {
    return {
      collaborators: { data: [], meta: {}, links: {} },
      errorMessage: "",
      totalEmployees: 0,
      localGroup: null,
      selected: [],
      selectedEmployeesIds: [],
      dialog: false,
      resolve: null,
      reject: null,
    }
  },
  computed: {
    companyUuid() {
      return this.$route.params.companyUuid
    },

    form() {
      return this.$refs.form
    },

    loaded() {
      return !!(this.localGroup && this.employees)
    },
  },
  methods: {
    open(group) {
      this.localGroup = JSON.parse(JSON.stringify(group))
      this.selectedEmployeesIds = this.localGroup.employees
        ? this.localGroup.employees.map((e) => e.id)
        : []
      this.dialog = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async loadEmployees(params = {}) {
      this.isLoading = true
      try {
        if (this.companyUuid) {
          const result = await EmployeeLogic.getAll(this.companyUuid, params)
          if (typeof result === "string") {
            this.errorMessage = result
            this.collaborators = { data: [], meta: {}, links: {} }
          } else {
            this.errorMessage = ""
            this.collaborators = result
            this.totalEmployees = result?.meta?.total || 0
          }
        } else {
          this.noCompanyDialogOpened = true
        }
      } catch (error) {
        this.displayAlert(error?.response?.data?.error?.message)
      } finally {
        this.isLoading = false
      }
    },
    async submit() {
      this.localGroup["employees"] =
        this.$refs.opEmployeeList.cachedSelectedEmployeesIds

      this.resolve(this.localGroup)
      this.dialog = false
    },
    close() {
      this.resolve(null)
      this.dialog = false
    },
  },
}
</script>
