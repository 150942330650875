<script>
import CompanyLogic from "@/logics/CompanyLogic"
import { showSnackbar } from "@/services/GlobalActions"
import { i18n } from "@/plugins/i18n"

export default {
  name: "company-customization-edition",
  props: {
    data: {
      default: null,
    },
  },
  data: function() {
    return {
      localData: null,
      customization: null,
      invalidJson: false,
    }
  },
  async mounted() {
    await this.init()
  },
  watch: {
    data(newValue, oldValue) {
      this.init()
    },
  },
  computed: {
    jsonCustomization: {
      get() {
        return JSON.stringify(this.customization, null, 2);
      },
      set(value) {
        try {
          this.invalidJson = false
          this.customization = JSON.parse(value);

        } catch (error) {
          this.invalidJson = true
        }
      },
    },
  },
  methods: {
    async init() {
      this.localData = JSON.parse(JSON.stringify(this.data))
      await this.getCustomization()
    },
    async getCustomization() {

      if (!this.localData.uuid) {
        return
      }

      this.customization = await CompanyLogic.getCustomization(
        this.localData.uuid,
      )
    },

    async resetCustomization(type) {
      if (!this.localData.uuid) {
        return
      }

      try{
        await CompanyLogic.deleteCustomization(this.localData.uuid)
        await this.getCustomization()
        showSnackbar(i18n.t("messages.successful_reset"))
      }catch(error){
        console.error(error)
      }
    },

    async updateCustomization() {
      if (!this.localData.uuid) {
        return
      }

      if(this.invalidJson) {
        showSnackbar(i18n.t("messages.invalid_json"), "#FF5252")
        return;
      }


      try {
        JSON.parse(this.jsonCustomization);
      } catch (error) {
        showSnackbar(i18n.t("messages.invalid_json"), "#FF5252")
        return;
      }
      const payload = JSON.parse(this.jsonCustomization)
      await CompanyLogic.updateCustomization(this.localData.uuid, payload);
      showSnackbar(i18n.t("messages.successful_update"))
    },
  },
}
</script>

<template>
  <!-- Customization -->
    <div class="bloc" v-if="localData && localData.uuid">
      <div>
      <v-row>
        <v-col cols="12">
          <div class="bloc-title">
            {{ $t("Customization") | titlecase }}
          </div>
        </v-col>

        <v-col cols="12">
          <div>
            <v-card-text>
              <v-textarea
                background-color="#efefef"
                class="json-display"
                v-model="jsonCustomization"
                :rows="18"
              ></v-textarea>

            </v-card-text>
          </div>
        </v-col>
      </v-row>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="resetCustomization()"
          small
        >{{ $t("buttons.reset") }}
        </v-btn>
        <v-btn
          small
          color="green"
          dark
          @click="updateCustomization()"
        >{{ $t("buttons.update") }}
        </v-btn>
      </v-card-actions>
    </div>
</template>


<style scoped>

.bloc {
  border: 1px solid #efefef;
  border-radius: 5px;
  flex: 1 1 auto;
  //width: 250px;
  padding: 20px;
  margin: 10px;
}


.bloc-title {
  font-weight: bold;
  //text-align: center;
  font-size: 1.5em;
  color: #2a2929;
}

.json-display textarea {
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}
</style>
