<!-- CompanyCourseForm.vue -->
<template>
  <v-expansion-panels
    v-if="localCourse"
    class="ma-1 program-preset-expansion-panels"
    v-model="panel"
    multiple
  >
    <v-expansion-panel>
    <v-list dense>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ $t("label.name") }}</v-list-item-title>
          <v-list-item-subtitle>{{ localCourse.name }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ $t("label.description") }}</v-list-item-title>
          <v-list-item-subtitle>{{ localCourse.description }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>


      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ $t("label.start_date") }}</v-list-item-title>
          <v-list-item-subtitle>{{ localCourse.configuration.start_date }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>


      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ $t("label.start_date") }}</v-list-item-title>
          <v-list-item-subtitle>{{ localCourse.configuration.start_date }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>


      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ $t("label.automatic_launch") }}</v-list-item-title>
          <v-list-item-subtitle>{{ localCourse.automation.automatic_launch ? "Yes" : "No" }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ $t("label.automatic_stop") }}</v-list-item-title>
          <v-list-item-subtitle>{{ localCourse.automation.automatic_stop ? "Yes" : "No" }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    </v-list>








    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import _ from "lodash"

export default {
  name: "CompanyCourseForm",
  props: {
    value: {
      type: Object,
      required: true,
    },
    hatCourses: {
      type: Array,
      default: null,
    },
    emailTypes: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    localCourse: null,
    menu1: false,
    panel: [0],
  }),
  async mounted() {
    this.localCourse = _.clone(this.value)
  },
  methods: {
    getLocalData() {
      return this.localCourse
    },
  },
}
</script>

<style lang="scss" scoped>
$color: #1876d2;

h4 {
  margin-bottom: 20px;
  border-bottom: $color dashed 1px;
  color: $color;
  text-transform: capitalize;
  width: 100%;
  display: block;

  i {
    font-size: 0.8em;
    color: #999;
  }
}
</style>
