import { log } from "async"
import Request from "../services/Request"
import { API_VERSION } from "./ApiVersion"

export default class CompanyRepository {
  static getOne(companyUuid, params = {}) {
    return Request.make("GET", `/${API_VERSION}/companies/${companyUuid}`, {
      params,
    })
  }

  static createOne(body = {}) {
    return Request.make("POST", `/${API_VERSION}/companies`, body)
  }

  static patchOne(companyUuid, body = {}) {
    return Request.make(
      "PATCH",
      `/${API_VERSION}/companies/${companyUuid}`,
      body,
    )
  }

  static deleteOne(companyUuid, params = {}) {
    return Request.make("DELETE", `/${API_VERSION}/companies/${companyUuid}`, {
      params,
    })
  }

  //----------------------- Access Control -----------------------
  static getCompanyRoles(companyUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/access-control/roles`,
    )
  }

  static getUserCompanyRoles(companyUuid, userUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/access-control/users/${userUuid}/roles`,
    )
  }

  static async addUserRoles(roleIds, roleSlugs, companyUuid, uuid) {
    const body = {
      role_ids: roleIds,
      role_slugs: roleSlugs,
    }

    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/access-control/users/${uuid}/roles`,
      body,
    )
  }

  static deleteUserRoles(roleIds, roleSlugs, companyUuid, uuid) {
    const params = {
      data: {
        role_ids: roleIds,
        role_slugs: roleSlugs,
      },
    }

    return Request.make(
      "DELETE",
      `/${API_VERSION}/companies/${companyUuid}/access-control/users/${uuid}/roles`,
      params,
    )
  }

  //----------------------- Phishing entities -----------------------
  static getCompanyPhishingEntities(companyUuid, path) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/phishing/${path}`,
    )
  }

  static getCompanyExerciseTemplateTagsList(companyUuid, exerciseId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/phishing/exercises/${exerciseId}/template-tags`,
    )
  }

  static getCompanyExerciseLandingPage(companyUuid, exerciseId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/phishing/exercises/${exerciseId}/landing-page`,
    )
  }

  static getCompanyExerciseEmailTemplate(companyUuid, exerciseId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/phishing/exercises/${exerciseId}/email-template`,
    )
  }

  static getCompanyExerciseSendingProfile(companyUuid, exerciseId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/phishing/exercises/${exerciseId}/sending-profile`,
    )
  }

  static getCompanyExerciseCompiledTemplates(companyUuid, exerciseId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/phishing/exercises/${exerciseId}/compiled-templates`,
    )
  }

  static getCompanyExerciseCompiledWithTemplateTags(
    companyUuid,
    exerciseId,
    tags,
  ) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/phishing/exercises/${exerciseId}/compiled-templates-with-tags`,
      tags,
    )
  }

  //----------------------- Notify users -----------------------
  static notifyCompanyUsers(companyUuid, body = {}) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/notifications/users`,
      body,
    )
  }

  //----------------------- Employee Groups -----------------------
  static getAllEmployeeGroups(companyUuid, withEmployees = false) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/employee-groups?with_employees=${withEmployees}`,
    )
  }

  static patchOneEmployeeGroup(companyUuid, group) {
    return Request.make(
      "PATCH",
      `/${API_VERSION}/companies/${companyUuid}/employee-groups/${group.id}`,
      group,
    )
  }

  static createOneEmployeeGroup(companyUuid, group) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/employee-groups`,
      group,
    )
  }

  static deleteOneEmployeeGroup(companyUuid, groupId) {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/companies/${companyUuid}/employee-groups/${groupId}`,
    )
  }

  static createOneSmartEmployeeGroup(companyUuid, rules) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/smart-employee-groups`,
      rules,
    )
  }

  static createMultipleEmployeeGroup(companyUuid, rules) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/employee-groups/multiple`,
      rules,
    )
  }

  static updateOneSmartEmployeeGroup(companyUuid, groupId, rules) {
    return Request.make(
      "PATCH",
      `/${API_VERSION}/companies/${companyUuid}/smart-employee-groups/${groupId}`,
      rules,
    )
  }

  static refreshOneSmartEmployeeGroup(companyUuid, group) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/smart-employee-groups/${group.id}/refresh`,
    )
  }

  static deleteOneEmployeeFromOneSmartEmployeeGroup(
    companyUuid,
    groupId,
    employeeId,
  ) {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/companies/${companyUuid}/smart-employee-groups/${groupId}/employees/${employeeId}`,
    )
  }

  //------------------------- Employee Filters ---------------------------
  static getEmployeeFilters(companyUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/employees/filters`,
    )
  }

  // ------------------------- One Employee ---------------------------
  static createOneNewEmployee(companyUuid, employee) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/employees`,
      {
        employees: [employee],
        mode: "flux",
      },
    )
  }

  static updateOneEmployee(companyUuid, employee) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/employees`,
      {
        employees: [employee],
        mode: "flux",
      },
    )
  }

  //----------------------- Users -----------------------
  static getCompanyUsers(companyUuid, selectedRoles = null) {
    let url = `/${API_VERSION}/companies/${companyUuid}/users?page_size=3000`

    // Check if any roles are selected and not 'all'
    if (
      selectedRoles &&
      selectedRoles.length &&
      !selectedRoles.includes("all")
    ) {
      const rolesParam = selectedRoles.join(",") // Join roles into a comma-separated string
      url += `&roles=${rolesParam}`
    }

    return Request.make("GET", url)
  }

  static createNewUser(companyUuid, body) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/users`,
      body,
    )
  }

  static updateUser(companyUuid, user) {
    return Request.make(
      "PATCH",
      `/${API_VERSION}/companies/${companyUuid}/users/${user.uuid}`,
      user,
    )
  }

  static getEmployeeHierarchy(companyUuid, userUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/users/${userUuid}/managed-employees?nested=true`,
    )
  }

  static convertUserToEmployee(companyUuid, user) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/users/${user.uuid}/convert-to-employee`,
    )
  }

  static deleteUser(companyUuid, user = {}) {
    return Request.make("DELETE", `/${API_VERSION}/companies/${companyUuid}/users/${user.uuid}`)
  }

  //----------------------- Employees -----------------------
  static getAllEmployees(companyUuid, params = {}) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/employees`,
      { params },
    )
  }

  static createNewEmployees(companyUuid, employeeList) {
    const data = {}
    data["mode"] = "stock" // alternative : 'flux'
    data["employees"] = employeeList
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/employees`,
      data,
    )
  }

  static deleteOneEmployee(companyUuid, employeeId) {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/companies/${companyUuid}/employees/${employeeId}`,
    )
  }

  static deactivateOneEmployee(companyUuid, employee) {
    const data = {
      employees: [
        {
          id: employee.id,
          informations: employee,
        },
      ],
    }
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/employees/deactivate`,
      data,
    )
  }

  static activateOneEmployee(companyUuid, employee) {
    const data = {
      employees: [
        {
          id: employee.id,
          informations: employee,
        },
      ],
    }
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/employees/activate`,
      data,
    )
  }

  // ------------------------- Programs ---------------------------
  static getAllPrograms(companyUuid) {
    const queryParams = [
      "with_campaigns=1",
      "with_simulations=1",
      "with_company_courses=1",
      "with_admin_infos=1",
      "with_company_course_stats=1",
      "with_simulation_stats=1",
      "sorts[id]=desc",
    ]

    const queryString = queryParams.join("&")

    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/programs?${queryString}`,
    )
  }


  static getOneProgram(companyUuid, programId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/programs/${programId}?with_campaigns=1&with_simulations=1&with_company_courses=1&with_admin_infos=1&with_company_course_stats=0`,
    )
  }

  static patchOneProgram(companyUuid, program) {
    return Request.make(
      "PATCH",
      `/${API_VERSION}/companies/${companyUuid}/programs/${program.id}`,
      program,
    )
  }

  static createOneProgram(companyUuid, program) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/programs`,
      program,
    )
  }

  static deleteOneProgram(companyUuid, programId) {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/companies/${companyUuid}/programs/${programId}`,
    )
  }

  // ------------------------- Campaigns ---------------------------
  static getAllCampaigns(companyUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/campaigns`,
    )
  }

  static getOneCampaign(companyUuid, campaignId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/campaigns/${campaignId}`,
    )
  }

  static patchOneCampaign(companyUuid, campaign) {
    return Request.make(
      "PATCH",
      `/${API_VERSION}/companies/${companyUuid}/campaigns/${campaign.id}`,
      campaign,
    )
  }

  static createOneCampaign(companyUuid, campaign) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/campaigns`,
      campaign,
    )
  }

  static deleteOneCampaign(companyUuid, campaignId) {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/companies/${companyUuid}/campaigns/${campaignId}`,
    )
  }

  //----------------------- Emails -----------------------
  static getAvailableEmailTypes(companyUuid, lang) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/email-types`,
      {
        params: {
          lang,
        },
      },
    )
  }

  static getEmailTypes(companyUuid, emailType, lang, hatcourseId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/email-types/${emailType}/${lang}/${hatcourseId}`,
    )
  }

  static generateCampaignReport(companyUuid, body) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/reports`,
      body,
    )
  }

  //----------------------- Logs -----------------------.
  static getCompanyLogs(companyUuid, queryParams) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/logs?${queryParams}`,
    )
  }

  //----------------------- Program Presets -----------------------
  static async getAllProgramPresets(companyUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/program-presets`,
      {},
    )
  }

  static async getOneProgramPreset(companyUuid, presetId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/program-presets/${presetId}`,
      {},
    )
  }

  static instantiateOneProgramPreset(companyUuid, presetId, body) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/program-presets/${presetId}`,
      body,
    )
  }

  //------------------------- Azure Active Directory ---------------------
  static async getAllUsersAzure(companyUuid, body) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/azure-active-directory/users`,
      body,
    )
  }

  //------------------------- Customization ---------------------
  static async getCustomization(companyUuid) {
    return Request.make("GET",
      `/${API_VERSION}/companies/${companyUuid}/customization`,
      {})
  }

  static async deleteCustomization(companyUuid) {
    return Request.make("DELETE",
      `/${API_VERSION}/companies/${companyUuid}/customization`,
      {})
  }

  static async updateCustomization(companyUuid, payload) {
    return Request.make("PATCH",
      `/${API_VERSION}/companies/${companyUuid}/customization`,
      payload)
  }

  //------------------------- SSO Azure ---------------------
  static async getConfigurationSsoAzure(companyUuid, type) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/azure-sso/configuration-azure-sso?type=${type}`,
      {},
    )
  }

  static async createConfigurationSsoAzure(companyUuid, body) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/azure-sso/configuration-azure-sso`,
      body,
    )
  }

  static async storeConfigurationSsoAzure(companyUuid, body) {
    return Request.make(
      "PATCH",
      `/${API_VERSION}/companies/${companyUuid}/azure-sso/configuration-azure-sso`,
      body,
    )
  }

  static async destroyConfigurationSsoAzure(companyUuid, type) {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/companies/${companyUuid}/azure-sso/configuration-azure-sso?type=${type}`,
    )
  }

  //------------------------- Admin Operations ---------------------
  static singleAdminAction(companyUuid, actionName) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/${actionName}`,
      {},
    )
  }


  //------------------------- Domains ---------------------
  static createDomain(companyUuid, domainName) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/domains`,
      domainName,
    )
  }

  static getAllDomains(companyUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/domains`,
    )
  }

  static getOneDomain(companyUuid, domainId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/domains/${domainId}`,
    )
  }

  static updateDomain(companyUuid, body, domainId) {
    log
    return Request.make(
      "PATCH",
      `/${API_VERSION}/companies/${companyUuid}/domains/${domainId}`,
      body,
    )
  }

  static deleteOneDomain(companyUuid, domainId) {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/companies/${companyUuid}/domains/${domainId}`,
    )
  }

  static checkOneDomain(companyUuid, domainId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/domains/${domainId}/verify`,
    )
  }

  static getToken(companyUuid, domainId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/domains/${domainId}/token`,
    )
  }

  //------------------------- CreditConsumption ---------------------

  static getSubscription(companyUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/subscription-plan`,
    )
  }

  static getBalance(companyUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/credit-balance`,
    )
  }


  static getNbActiveEmployeesHistory(companyUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/active-employees-history`)
  }

  static getDetailedNbActiveEmployeesHistory(companyUuid, year = null, month = null) {
    let url = `/${API_VERSION}/companies/${companyUuid}/detailed-active-employees-history`;
    if(year){
      url += `?year=${year}`;
    }
    if (month) {
      url += `&month=${month}`;
    }
    return Request.make(
      "GET",
      url)
  }

  static getCreditConsumptionHistory(companyUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/credit-consumption-history`,
    )
  }

  static getDetailedCreditConsumptionHistory(companyUuid, year = null, month = null) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/detailed-credit-consumption-history`, { params: { year, month } },
    )
  }

  static updateSubscriptionType(companyUuid, body) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/subscription-plan`,
      body,
    )
  }

  static purchaseCredits(companyUuid, payload) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/buy-credits`,
      payload,
    )
  }

  // ------------- GetEmployeeListCsv --------------//

  static getEmployeeList(companyUuid) {
    return Request.blob(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/employees/csv`,
    )
  }

// ------------- purifyHtml --------------

  static async purifyHtml(companyUuid, html) {

    const body = {
      html: html,
    }

    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/html/purify`,
      body,
    )
  }

  // ------------- IPs and Blacklisted IPs --------------
  static async getAllIps(companyUuid, { page, per_page, sortBy = [], sortDesc = [], search = "" }) {
    let params = {
      page,
      per_page,
      search,
    }

    if (sortBy.length > 0) {
      params.sort_by = sortBy[0]
      params.sort_desc = sortDesc[0]
    }

    return Request.make("GET", `/${API_VERSION}/companies/${companyUuid}/ip-addresses`, { params })
  }

  static async blacklistOneIp(companyUuid, body) {
    return Request.make("POST", `/${API_VERSION}/companies/${companyUuid}/ip-addresses/blacklist`, body)
  }

  static async unblacklistOneIp(companyUuid, ip) {
    const body = {
      ip: ip,
    }
    return Request.make("POST", `/${API_VERSION}/companies/${companyUuid}/ip-addresses/unblacklist`, body)
  }

}
