<template>
  <v-container style="background-color: white">
    <op-confirmation-dialog ref="confirm" :showButton="true" />

    <!-- Blacklist Dialog -->
    <op-ip-blacklist
      v-model="showDialog"
      v-if="selectedIp"
      :ip="selectedIp"
      @confirmed="handleBlacklistConfirmed"
      @cancel="selectedIp = null"
      @error="handleDialogError"
    />

    <!-- Search Bar -->
    <v-row v-if="!noSearchBar">
      <v-col cols="12">
        <v-text-field
          v-model="searchText"
          :label="$t('label.search')"
          append-icon="mdi-magnify"
          @input="onSearch"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Paginated IPs Table -->
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="ips"
          :options.sync="options"
          :server-items-length="totalItems || 0"
          :loading="loading"
          class="elevation-1"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250, 500],
          }"
        >
          <!-- Custom actions column -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn v-if="item.is_blacklisted" color="red" text @click.stop="confirmUnblacklist(item)">
              Unblacklist
            </v-btn>
            <v-btn v-else color="green" text @click.stop="confirmBlacklist(item)">
              Blacklist
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import debounce from "lodash/debounce";
import { showSnackbar } from "@/services/GlobalActions";
import { i18n } from "@/plugins/i18n";

export default {
  name: "op-ip-list",
  props: {
    noSearchBar: {
      type: Boolean,
      required: false,
    },
    ips: {
      type: Array,
      required: true,
    },
    totalItems: {
      type: Number,
      required: false,  // Rend cette propriété optionnelle pour éviter l'erreur
      default: 0,       // Définit une valeur par défaut
    },
  },
  data() {
    return {
      headers: [
        { text: this.$t("label.ip_address"), value: "ip", sortable: true },
        { text: this.$t("ip_addresses.ip_range"), value: "range", sortable: true },
        { text: this.$t("label.country"), value: "country", sortable: true },
        { text: this.$t("label.city"), value: "city", sortable: true },
        { text: this.$t("label.isp"), value: "isp", sortable: true },
        { text: this.$t("ip_addresses.is_blacklisted"), value: "is_blacklisted", sortable: true },
        { text: this.$t("label.created_at"), value: "created_at", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
      searchText: "",
      loading: false,
      selectedIp: null,
      showDialog: false,
      options: {
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: [],
      },
    };
  },
  watch: {
    "options.page"(newPage) {
      this.debounceRefresh()
    },
    "options.itemsPerPage"(newPerPage) {
      this.debounceRefresh()
    },
    "options.sortBy"(newSortBy) {
      this.debounceRefresh()
    },
    "options.sortDesc"(newSortDesc) {
      this.debounceRefresh()
    },
  },
  mounted() {
    this.emitRefresh();
  },
  methods: {
    debounceRefresh: debounce(async function () {
      await this.emitRefresh()
    }, 600),

    emitRefresh() {
      this.$emit("refresh", {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        search: this.searchText,
      });
    },
    onSearch: debounce(function () {
      this.options.page = 1;
      this.emitRefresh();
    }, 600),
    confirmBlacklist(item) {
      this.selectedIp = item.ip;
      this.showDialog = true;
    },
    async handleBlacklistConfirmed(response) {
      this.$emit("blacklist", response);
      this.selectedIp = null;
      this.showDialog = false;
    },
    async confirmUnblacklist(item) {
      if (
        await this.$refs.confirm.open(
          i18n.t("ip_addresses.confirm_unblacklist"),
          i18n.t("ip_addresses.confirm_unblacklist_message"),
          i18n.t("buttons.cancel")
        )
      ) {
        this.$emit("unblacklist", item.ip);
      }
    },
    handleDialogError(message) {
      showSnackbar(message || i18n.t("error.default"), "#FF5252");
    },
  },
};
</script>
