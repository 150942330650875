<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    eager
    @keydown.esc="close"
  >
    <v-card class="d-flex flex-column" style="height: 100%;">

      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>

        <v-btn
          v-if="!options.noconfirm"
          color="grey"
          text
          class="body-2 font-weight-bold text-none rounded-lg"
          @click.native="cancel"
        >{{ $t("view.companies.give_up") }}
        </v-btn>

        <v-btn
          color="green"
          class="body-2 font-weight-bold text-none rounded-lg"
          outlined
          @click.native="save"
        >{{ $t("buttons.save") }}
        </v-btn>
      </v-card-actions>


      <v-card-text class="pa-4"  style="flex: 1 1 auto; overflow-y: auto;">
        <div style="max-width:600px; margin: 0 auto;">
        <v-row>
          <v-text-field v-model="name" :label="$t('employee_groups.labels.smart_group_name')" required></v-text-field>
        </v-row>
        <v-row>

          <v-checkbox
            name="manualDeletion"
            v-model="manualDeletion"
            :label="$t('view.companies.manual_employee_deletion')"
          >
          </v-checkbox>

          <v-dialog v-model="dialogMessage" max-width="500px">
            <v-card>
              <v-card-text class="pt-4">{{ $t("view.companies.smart_group_message") }}</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialogMessage = false">
                  {{ $t("buttons.close") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-icon
            class="ma-2 pa-2"
            color="primary"
            style="cursor: pointer;"
            @click="dialogMessage = true"
          >
            mdi-help-circle
          </v-icon>
        </v-row>

        <v-row>
          <v-select
            v-model="smartGroupType"
            :items="smartGroupTypes"
            chips
            :label="$t('employee_groups.labels.smart_group_type')"
          ></v-select>
        </v-row>

        <v-container v-if="'from_simulations' === smartGroupType" style="background-color: transparent;">
          <v-row v-if="simulations">
            <v-select
              v-model="selectedSimulations"
              :items="simulations"
              multiple
              chips
              item-text="name"
              item-value="id"
              :label="$t('view.companies.Simulations_selection')"
            ></v-select>
          </v-row>

          <v-row v-if="simulations">
            <v-switch
              v-model="hookedInSimulation"
              hide-details
              inset
              :label="$t('view.companies.employees_phished') + ` : ${hookedInSimulation.toString()}`"
            ></v-switch>
          </v-row>
        </v-container>

        <v-container v-if="'new_employees' === smartGroupType" style="background-color: transparent;">
          <div>
            {{ $t("employee_groups.smart_groups.information.from_employees.message_1") }}
          </div>
          <div>
            <span style="color:indianred">{{ $t("employee_groups.smart_groups.information.from_employees.message_2") }}</span>
          </div>
        </v-container>

        <v-container v-if="'all_staff' === smartGroupType" style="background-color: transparent;">
          <div class="ma-2">
            <i>{{ $t("view.companies.group_creation_with_all_active_employees") }}</i>
          </div>

          <div><b>{{ $t("view.companies.filters") }}: </b></div>

          <v-row class="mt-4">
            <v-col cols="6">
              <v-select
                v-model="selectedFilters.langs"
                :items="employeeFilters.langs"
                multiple
                chips
                :label="$t('view.learning_phishing.languages')"
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="selectedFilters.entities"
                :items="employeeFilters.entities"
                multiple
                chips
                :label="$t('view.companies.entities')"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-select
                v-model="selectedFilters.jobs"
                :items="employeeFilters.jobs"
                multiple
                chips
                :label="$t('view.companies.jobs')"
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="selectedFilters.manager_emails"
                :items="employeeFilters.manager_emails"
                multiple
                chips
                :label="$t('view.companies.manager_emails')"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-select
                v-model="selectedFilters.departments"
                :items="employeeFilters.departments"
                multiple
                chips
                :label="$t('view.companies.deparments')"
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="selectedFilters.countries"
                :items="employeeFilters.countries"
                multiple
                chips
                :label="$t('view.companies.countries')"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-select
                v-model="selectedFilters.cities"
                :items="employeeFilters.cities"
                multiple
                chips
                :label="$t('view.companies.cities')"
                :no-data-text="$t('label.no-available-data')"
              ></v-select>
            </v-col>
            <v-col cols="6"></v-col>
          </v-row>
        </v-container>
        </div>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import EmployeeLogic from "@/logics/EmployeeLogic"

export default {
  name: "op-smart-group",
  provide: function () {
    return {
      deleteOneEmployee: EmployeeLogic.deleteOne,
      updateOneEmployee: EmployeeLogic.updateOne,
      createOneEmployee: EmployeeLogic.createOne,
      createEmployeeList: EmployeeLogic.createList,
      activateEmployeeList: EmployeeLogic.activateList,
      deactivateEmployeeList: EmployeeLogic.deactivateList,
    }
  },
  data() {
    return {
      dialog: false,
      dialogMessage: false,
      selectedSimulations: null,
      resolve: null,
      reject: null,
      smartGroupType: null,
      options: {
        color: "grey lighten-3",
        width: '80%',
        maxWidth: 1300,
        zIndex: 200,
        noconfirm: false,
      },
      simulations: null,
      employeeFilters: null,
      selectedFilters: null,
      manualDeletion: false,
      name: "",
      hookedInSimulation: true,
    }
  },
  computed: {
    smartGroupTypes() {
      return [
        { value: 'all_staff', text: this.$t("employee_groups.smart_groups.all_staff") },
        { value: 'from_simulations', text: this.$t("employee_groups.smart_groups.from_simulations") },
        { value: 'new_employees', text: this.$t("employee_groups.smart_groups.new_employees") }
      ];
    }
  },
  methods: {
    open(simulations, employeeFilters, rules = null) {

      this.simulations = simulations

      this.employeeFilters = employeeFilters

      this.selectedFilters = {
        cities: [],
        langs: [],
        manager_emails: [],
        entities: [],
        jobs: [],
        departments: [],
        countries: [],
      }

      if (rules && rules.type) {
        this.smartGroupType = rules.type

        if ("from_simulations" === rules.type) {
          this.selectedSimulations = rules.simulations_ids
          this.hookedInSimulation =
            rules.simulations_status === "hooked" ||
            rules.simulations_status === "failed"
        } else if ("all_staff" === rules.type && rules.filters) {
          this.selectedFilters = rules.filters
        }
      } else {
        this.smartGroupType = null
      }

      if (rules && rules.manual_deletion) {
        this.manualDeletion = rules.manual_deletion
      } else {
        this.manualDeletion = false
      }

      this.name = rules && rules.name ? rules.name : ""

      this.dialog = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    save() {
      let rules = {}
      rules.name = this.name
      rules.manual_deletion = this.manualDeletion
      rules.type = this.smartGroupType

      if ("from_simulations" === this.smartGroupType) {
        if (!this.selectedSimulations || this.selectedSimulations.length <= 0) {
          this.cancel()
        }

        rules.simulations_ids = this.selectedSimulations
        rules.simulations_status = this.hookedInSimulation
          ? "hooked"
          : "not_hooked" //failed
      } else if ("all_staff" === this.smartGroupType) {
        rules.filters = this.selectedFilters
      }

      this.resolve(rules)
      this.dialog = false
    },

    cancel() {
      this.resolve(null)
      this.dialog = false
    },
  },
}
</script>
