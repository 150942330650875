import Request from "../services/Request"
import { API_VERSION } from "@/repositories/ApiVersion"

export default class LearningRepository {
  //------------------- Generic Resources  -------------------
  static duplicateOneResource(type, id) {
    return Request.make("POST", `v1/learning/${type}/${id}/duplicate`)
  }

  static getResources(type) {
    return Request.make("GET", `v1/learning/${type}`)
  }

  static patchOneResource(type, object) {
    return Request.make("PATCH", `v1/learning/${type}/${object.id}`, object)
  }

  static deleteOneResource(type, id) {
    return Request.make("DELETE", `v1/learning/${type}/${id}`)
  }

  static createOneResource(type, object) {
    return Request.make("POST", `v1/learning/${type}`, object)
  }
//------------------- s3  -------------------
  static getDirectories() {
    return Request.make("GET", `v1/learning/directories`)
  }

  static createDirectory(name) {
    return Request.make("POST", `v1/learning/directories`, { name })
  }

  static getFiles(directory) {
    return Request.make(
      "GET",
      `v1/learning/assets?directory=${encodeURIComponent(directory)}`
    )
  }

  static deleteFile(formData) {
    return Request.make("POST", `v1/learning/assets/file`, formData)
  }

  static uploadFile(formData) {
    return Request.upload(`v1/learning/assets`, formData)
  }
  //------------------- Hat Courses  -------------------
  static getAvailableHatCourses(companyUuid) {
    return Request.make(
      "GET",
      `v1/companies/${companyUuid}/learning/hat-courses`
    )
  }

  static getAllCourses(companyUuid) {
    return Request.make(
      "GET",
      `/v1/companies/${companyUuid}/company-courses?with_admin_infos=1&sorts[id]=desc`
    )
  }

  static getEmployees(companyUuid, params = {}) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/company-courses/employees`,
      { params }
    )
  }

  static getOneCourse(companyUuid, courseId) {
    return Request.make(
      "GET",
      `v1/companies/${companyUuid}/company-courses/${courseId}`
    )
  }

  static patchOneCourse(companyUuid, course) {
    return Request.make(
      "PATCH",
      `v1/companies/${companyUuid}/company-courses/${course.id}`,
      course
    )
  }

  static createOneCourse(companyUuid, course) {
    return Request.make(
      "POST",
      `v1/companies/${companyUuid}/company-courses`,
      course
    )
  }

  static deleteOneCourse(companyUuid, courseId) {
    return Request.make(
      "DELETE",
      `v1/companies/${companyUuid}/company-courses/${courseId}`
    )
  }

  static startOneCourse(companyUuid, courseId) {
    return Request.make(
      "GET",
      `v1/companies/${companyUuid}/company-courses/${courseId}/start`
    )
  }

  static stopOneCourse(companyUuid, courseId) {
    return Request.make(
      "GET",
      `v1/companies/${companyUuid}/company-courses/${courseId}/stop`
    )
  }

  static getOneCourseResults(companyUuid, courseId) {
    return Request.make(
      "GET",
      `v1/companies/${companyUuid}/company-courses/${courseId}/results`
    )
  }

  static inviteUsersToCourse(companyUuid, courseId) {
    return Request.make(
      "GET",
      `v1/companies/${companyUuid}/company-courses/${courseId}/invite`
    )
  }

  static remindUsersOfCourse(companyUuid, courseId) {
    return Request.make(
      "GET",
      `v1/companies/${companyUuid}/company-courses/${courseId}/remind`
    )
  }

  static duplicateCourseWithSlides(courseId) {
    return Request.make("GET", `v1/learning/courses/${courseId}/duplicate`)
  }

  static courseDownloadScorm(courseId, data) {
    return Request.blob(
      "POST",
      `v1/learning/courses/${courseId}/download`,
      data
    )
  }

  static moveSlides(object) {
    return Request.blob("POST", `v1/learning/slides/move`, object)
  }

  // ------------------- Export Data -------------------

  static export(companyUuid, companyCourseId, type) {
    let url = ""

    if ("completed-sessions" === type) {
      url = `/${API_VERSION}/companies/${companyUuid}/company-courses/${companyCourseId}/exports/completed-sessions`
    } else if ("results" === type) {
      url = `/${API_VERSION}/companies/${companyUuid}/company-courses/${companyCourseId}/exports/results`
    } else {
      return null
    }

    return Request.blob("GET", url)
  }
}
